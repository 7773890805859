import { useTranslate, useApiUrl, useList } from "@pankod/refine-core";

import {
  Edit,
  Drawer,
  DrawerProps,
  Form,
  FormProps,
  Input,
  Radio,
  Select,
  Space,
  ButtonProps,
  Avatar,
  Upload,
  Grid,
  Button,
  Icons,
  UploadFile
} from "@pankod/refine-antd";

import { IMember } from "interfaces";
import { useEffect, useState } from "react";
import { TOKEN_KEY } from "authProvider";

type EditFileProps = {
  drawerProps: DrawerProps;
  formProps: FormProps;
  saveButtonProps: ButtonProps;
};

export const EditFile: React.FC<EditFileProps> = ({
  drawerProps,
  formProps,
  saveButtonProps,
}) => {
  const t = useTranslate();
  const apiUrl = useApiUrl();
  const breakpoint = Grid.useBreakpoint();
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState<UploadFile[]>([

  ]);
  
  const { data } = useList<IMember>({
    resource: "users"
  });

  const operators = data?.data || []

  useEffect(() => {
    if (formProps?.initialValues) {
      form.setFieldsValue({ 
        ...formProps.initialValues 

      });
      setFileList(formProps.initialValues.fileUrl)
    }
  }, [formProps.initialValues])

  const setFileName = (fileName: string) => {
    form.setFieldsValue({
      fileName
    })
  }

  return (
    <Drawer
      {...drawerProps}
      width={breakpoint.sm ? "500px" : "100%"}
      bodyStyle={{ padding: 0 }}
      zIndex={1001}
    >
      <Edit
        saveButtonProps={{
          ...saveButtonProps,
          onClick: () =>form.submit(),
         style: { backgroundColor: "#3f3c7e", border: 'none' }
        }}
        pageHeaderProps={{ extra: null }}
        resource="files"
      >
        <Form {...formProps} layout="vertical" form={form}>
          <Form.Item>
            <Form.Item
              name="fileUrl"
              valuePropName="fileList"
              getValueFromEvent={(event) => {
                const { fileList } = event;
                const fileUrl = fileList.map((_: any) => ({..._, ..._.response && { url: _.response.url} }))
                if(fileUrl?.length > 0) { setFileName(fileUrl[0].originDocumentObj?.name as string) }
                return [...fileList];
              }}              
              noStyle
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Upload.Dragger
                name="file"
                action={`${apiUrl}/icons/upload`}
                headers={{
                  "x-token": `${localStorage.getItem(TOKEN_KEY)}`,
                }}
                listType="text"
                maxCount={1}
                style={{ border: "none" }}
                fileList={fileList}
                onChange={(info) => {
                  let newDocumentList = [...info.fileList];

                  newDocumentList = newDocumentList.slice(-2);

                  newDocumentList = newDocumentList.map((file) => {
                    if (file.response) {
                      file.url = file.response.url;
                    }
                    return file;
                  });

                  setFileList(newDocumentList);
                }}
              >
                <Space direction="vertical" size={2} style={{ display: "flex", alignItems: 'start' }}>
                  <Button icon={<Icons.UploadOutlined />}>{t("files.fields.upload")}</Button>
                </Space>
              </Upload.Dragger>
            </Form.Item>
          </Form.Item>
          <Form.Item
            label={t("files.fields.fileName")}
            name="fileName"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t("files.fields.visibleForUserIds")}
            name='visibleForUserIds'
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              mode="multiple"
              defaultValue={formProps?.initialValues?.visibleForUserIds || []}
              options={operators?.map((_: IMember) => ({
                  label: (
                    <span style={{ display: "inline-flex" }}>
                      {_.displayName}
                      <Avatar
                        size={{
                          md: 20,
                          lg: 20,
                          xl: 20,
                          xxl: 20,
                        }}
                        src={_?.avatarUrl[0]?.url || "/images/profile.png"}
                      />
                    </span>
                  ),
                  value: _.id
                 ?? []}))}
            />
          </Form.Item>
          <Form.Item
            label={t("users.fields.isActive.label")}
            name="isActive"
            rules={[{ required: true }]}
            initialValue={false}
          >
            <Radio.Group>
              <Radio value={true}>{t("status.enable")}</Radio>
              <Radio value={false}>{t("status.disable")}</Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Edit>
    </Drawer>
  );
};
