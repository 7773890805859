import { useTranslate } from "@pankod/refine-core";

import {
    Input,
    Modal,
    Card,
    Row,
    Button,
    Divider,
    Icons,
    Typography
} from "@pankod/refine-antd";
import { useState } from "react";
import api from "httpClient";
import { PASSWORD_CHANGE } from "authProvider";

type EditChangePassword = {
    userId?: string;
    visible: boolean;
    setVisible: Function
};

export const ModalChangePassword: React.FC<EditChangePassword> = ({
    userId,
    visible,
    setVisible
}) => {
    const t = useTranslate();
    const [loading, setLoading] = useState(false)
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [errorMessage, setErrorMessage] = useState(false)
    const [errorMinMessage, setErrorMinMessage] = useState(false)
    const [passwordShown, setPasswordShown] = useState(false);
    const [passwordConfirmShown, setPasswordConfirmShown] = useState(false);

    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };

    const toggleConfirmPassword = () => {
        setPasswordConfirmShown(!passwordConfirmShown);
    };

    return (
      <Modal
        title={<div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>Cambia password</div>}
        centered
        visible={visible}
        onOk={() => setVisible(false)}
        footer={null}
        closable={false}
      >
        <Card bordered={false} style={{ height: "98%", width: "100%", border: '1px black', boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.5)' }}>
          <div style={{ height: '98%', width: '95%' }}>
            <Row>
              <Typography.Text>
                {"Nuova Password"}

              </Typography.Text>
              <Input
                type={passwordShown ? "text" : "password"}
                placeholder="●●●●●●●●"
                size="small"
                min={8}
                onChange={(e) => {
                    setErrorMessage(false)
                    setErrorMinMessage(false)
                    setPassword(e.target.value)
                }}
                prefix={
                    <Button
                    key={"passwordRefresh"}
                    type="ghost"
                    block
                    icon={<Icons.EyeFilled />}
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        border: "none",
                    }}
                    onClick={() => togglePassword()}
                    >
                    {" "}
                    </Button>
                }
            />
            </Row>
            <Divider />
            <Row>
              <Typography.Text>
                {"Conferma Password"}

              </Typography.Text>
              <Input
                  type={passwordConfirmShown ? "text" : "password"}
                  placeholder="●●●●●●●●"
                  size="small"
                  min={8}
                  onChange={(e) => {
                      setErrorMessage(false)
                      setErrorMinMessage(false)
                      setConfirmPassword(e.target.value)
                  }}
                  prefix={
                      <Button
                      key={"passwordRefresh"}
                      type="ghost"
                      block
                      icon={<Icons.EyeFilled />}
                      style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          border: "none",
                      }}
                      onClick={() => toggleConfirmPassword()}
                      >
                      {" "}
                      </Button>
                  }
              />
            </Row>
            <Divider />
            <Row>
              <Button style={{ width: "100%", backgroundColor: "#3f3c7e" }} onClick={async () => {
                const url = `/users/${userId}`;
                setLoading(true);

                if (!password || !confirmPassword || password !== confirmPassword) {
                  setErrorMessage(true)
                  return
                }

                if (password.length < 8) {
                  setErrorMinMessage(true)
                  return
                }

                try {
                  await api.put(url, {
                    password
                  });
                  setVisible(false)
                  localStorage.setItem(PASSWORD_CHANGE, new Date().toISOString())
                } catch (e: any) {
                  //nothing
                }
              }} type="primary">
                {"Salva"}
              </Button>
            </Row>
            {errorMinMessage ? (<p style={{ display: 'flex', justifyContent: 'center', color: "red" }}>Minimo 8 caratteri</p>) : null}
            {errorMessage ? (<p style={{ display: 'flex', justifyContent: 'center', color: "red" }}>Password non coincidono</p>) : null}
          </div>
        </Card>
      </Modal>
    );
};