import { AuthProps } from "@pankod/refine-antd";
import { LoginPage } from "components";

const renderAuthContent = (content: React.ReactNode) => {

    return (
        <div
            style={{
                maxWidth: 408,
                margin: "auto",
            }}
        >
            {content}
        </div>
    );
};

export const AuthPage: React.FC<AuthProps> = ({ formProps }) => {
    return <LoginPage
        renderContent={renderAuthContent}
        formProps={formProps}
    />;
};
