import React, { useEffect } from "react";
import { Refine } from "@pankod/refine-core";
import { RefineKbar, RefineKbarProvider } from "@pankod/refine-kbar";
import routerProvider from "@pankod/refine-react-router-v6";
import {
    ConfigProvider,
    Avatar,
    Space,
    Typography,
    AntdLayout,
} from "@pankod/refine-antd";
import jsonServerDataProvider from "apiWrapper"

import it_IT from "antd/lib/locale/it_IT"
import { ROLE_KEY, authProvider } from "authProvider";
import dayjs from "dayjs";

import "antd.less";

import { AuthPage } from "./login";
import { UserList, UserShow } from "./users";
import { useTranslation } from "react-i18next";
import { Header, Title } from "components";

import { newEnforcer } from "casbin";
import { adapter, model } from "roles";

import axiosInstance from "httpClient";
import { toastProvider } from "toastProvider";
import { UserLogList } from "logs";
import { ShiftList } from "shifts";
import { baseUrl } from "utils";
import { Layout } from "components/layout";
import { TasksList } from "tasks";
import { FilesList } from "files";
import { NewsList } from "news";
import { NoticesList } from "notices";

const App: React.FC = () => {
    const API_URL = baseUrl().adminUrl;
    const { t, i18n } = useTranslation();

    const dataProvider = jsonServerDataProvider(API_URL, axiosInstance, t);

    const i18nProvider = {
        translate: (key: string, params: object) => t(key, params),
        changeLocale: (lang: string) => i18n.changeLanguage(lang),
        getLocale: () => i18n.language,
    };

    const locale = i18nProvider.getLocale();

    useEffect(() => {
        if (locale === "it") {
            dayjs.locale("it");
        } else {
            dayjs.locale("en");
        }
    }, [locale]);

    const downloadApp = () => {
        axiosInstance.get(baseUrl().apiUrl + "/app/binaries", {
            responseType: 'blob'
        }).then((response: { data: Blob | MediaSource; }) => {
            // create file link in browser's memory
            const href = URL.createObjectURL(response.data);
        
            // create "a" HTML element with href to file & click
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', "app-release.apk"); //or any other extension
            document.body.appendChild(link);
            link.click();
        
            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        }).catch(() => toastProvider.open({ message: "Errore durante il download", type: 'error' }))
    }

    return (
        <RefineKbarProvider>
            <ConfigProvider locale={locale === "it" ? it_IT : undefined}>
                <Refine
                    accessControlProvider={{
                        can: async ({ resource, action }) => {
                            const role = localStorage.getItem(ROLE_KEY) ?? "notauthorized"
                            const enforcer = await newEnforcer(model, adapter);
                            const can = await enforcer.enforce(role, resource, action);

                            return Promise.resolve({
                                can,
                            });
                        },
                    }}
                    authProvider={authProvider(t)}
                    routerProvider={routerProvider}
                    dataProvider={dataProvider}
                    i18nProvider={i18nProvider}
                    OffLayoutArea={() => (
                        <div className="toggle-container">
                            <RefineKbar />
                        </div>
                    )}
                    notificationProvider={toastProvider}
                    LoginPage={() => (
                        <AuthPage
                            type="login"
                        />
                    )}
                    Title={Title}
                    Header={Header}
                    Layout={Layout}
                    Sider={() => null}
                    Footer={() => (
                        <AntdLayout.Footer style={{
                            height: "64px", 
                            backgroundColor: "#3f3c7e", 
                            position: "sticky",
                            bottom: "0px",
                            zIndex: 2
                        }}>
                            <Space direction="horizontal" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>
                                <Typography.Title style={{ color: 'white', fontSize: '14px', textDecoration: 'underline' }} onClick={() => downloadApp()}>Clicca qui per scaricare l'app <Avatar shape="square" size={32} src="/images/app.png" /></Typography.Title>
                            </Space>
                        </AntdLayout.Footer>
                    )}
                    options={{
                        syncWithLocation: true,
                        warnWhenUnsavedChanges: false,
                        disableTelemetry: true
                    }}
                    resources={[
                        {
                            name: "shifts",
                            list: ShiftList,
                            icon: <Avatar shape="square" size={24} src="/images/notices-menu.png" />,
                            options: {
                                route: "service/shifts",
                                label: 'Turni'
                            },
                        },
                        {
                            name: "users",
                            list: UserList,
                            show: UserShow,
                            icon: <Avatar shape="square" size={24} src="/images/users-menu.png" />,
                            options: {
                                route: "service/users",
                                label: "Utenti"
                            }
                        },
                        {
                            name: "tasks",
                            list: TasksList,
                            icon: <Avatar shape="square" size={24} src="/images/notices-menu.png" />,
                            options: {
                                route: "service/tasks",
                                label: 'Task'
                            },
                        },
                        {
                            name: "notices",
                            list: NoticesList,
                            icon: <Avatar shape="square" size={24} src="/images/notices-menu.png" />,
                            options: {
                                route: "service/notices"
                            },
                        },
                        {
                            name: "news",
                            options: {
                                label: "Comunicazioni",
                                route: "service/news"
                            },
                            list: NewsList,
                            icon: <Avatar shape="square" size={24} src="/images/time.png" />,
                        },
                        {
                            name: "files",
                            options: {
                                label: "Documenti",
                                route: "service/files"
                            },
                            list: FilesList,
                            icon: <Avatar shape="square" size={24} src="/images/files-menu.png" />,
                        },
                        {
                            name: "logs",
                            list: UserLogList,
                            icon: <Avatar shape="square" size={24} src="/images/log-menu.png" />,
                            options: {
                                route: "service/logs",
                                label: 'Attività'
                            },
                        }
                    ]}
                    catchAll={<UserList />}
                />
            </ConfigProvider>
        </RefineKbarProvider>
    );
};

export default App;
