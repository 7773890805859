import {
  useTranslate,
  IResourceComponentsProps,
  CrudFilters,
  HttpError,
  useExport,
  useModal
} from "@pankod/refine-core";

import {
  Table,
  useTable,
  Row,
  Col,
  Button,
  Space,
  Avatar,
  DateField,
  Typography,
  ExportButton,
  Modal,
  Icons,
  DatePicker,
  Form,
  Input,
  DeleteButton,
  Tag,
  Drawer
} from "@pankod/refine-antd";
import { IShift, IShiftFilterVariables } from "../interfaces";
import "./style.less";
import { useEffect, useState } from "react";
import { ModalChangePassword } from "components/change";
import { PASSWORD_CHANGE, USER_KEY } from "authProvider";
import { MapContainer, Marker, Popup, TileLayer, useMap } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import { Icon } from 'leaflet'
import { baseUrl } from "utils";
import { Helmet } from 'react-helmet';
import dayjs from "dayjs";

const exitIcon = new Icon({
  iconUrl: '/images/marker-exit.png',
  iconSize: [38, 45], // size of the icon
  iconAnchor: [22, 94], // point of the icon which will correspond to marker's location
  popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
})

const entranceIcon = new Icon({
  iconUrl: '/images/marker-entrance.png',
  iconSize: [38, 45], // size of the icon
  iconAnchor: [22, 94], // point of the icon which will correspond to marker's location
  popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
})

function getFullDate(partitionDate: number, hour: number, minute: number) {
  const year = parseInt(partitionDate.toString().substring(0, 4));
  const month = parseInt(partitionDate.toString().substring(4, 6)) - 1;
  const day = parseInt(partitionDate.toString().substring(6));

  const date = new Date(year, month, day, hour, minute);

  return date
}

export const ShiftList: React.FC<IResourceComponentsProps> = () => {
  const userPasswordChanged = localStorage.getItem(PASSWORD_CHANGE)
  const userId = localStorage.getItem(USER_KEY)
  const [modelOpen, setModelOpen] = useState(false);
  const { visible, show, close } = useModal();
  const MAP_API_URL = baseUrl().mapUrl

  const [startLat, setStartLat] = useState(0)
  const [startLong, setStartLong] = useState(0)
  const [endLat, setEndLat] = useState(0)
  const [endLong, setEndLong] = useState(0)

  const { RangePicker } = DatePicker;
  const [openFilter, setOpenFilter] = useState(false);
  const [form] = Form.useForm();
  const [isFilterApplied, setIsFilterApplied] = useState(false)
  
  useEffect(() => {
    form.setFieldsValue({
      createdAt: [dayjs().hour(0).minute(0).second(0), dayjs().hour(23).minute(59).second(59)],
      q: undefined
    })
    if (!userPasswordChanged) {
      setModelOpen(true)
    }
  }, [])

  const { tableProps, sorter, filters, searchFormProps } = useTable<
    IShift,
    HttpError,
    IShiftFilterVariables
  >({
    initialSorter: [
      {
        field: "createdAt",
        order: "desc",
      },
    ],
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { q, createdAt } = params;

      filters.push({
        field: "q",
        operator: "eq",
        value: q,
      });

      filters.push(
        {
          field: "createdAt",
          operator: "gte",
          value: createdAt
            ? createdAt[0].startOf("day").toISOString()
            : undefined,
        },
        {
          field: "createdAt",
          operator: "lte",
          value: createdAt
            ? createdAt[1].endOf("day").toISOString()
            : undefined,
        }
      );

      return filters;
    },
    syncWithLocation: false,
  });

  const t = useTranslate();

  function appendQueryParams(item: IShift) {
    let queryParams = ''

    if (item.startPosition && Object.keys(item.startPosition).length > 0) {
      queryParams += `slat=${item.startPosition.lat}&slong=${item.startPosition.long}`
    }
    
    if (item.endPosition && Object.keys(item.endPosition).length > 0) {
      queryParams += `&elat=${item.endPosition.lat}&elong=${item.endPosition.long}`
    }

    return queryParams
  }

  const { isLoading, triggerExport } = useExport<IShift>({
    sorter,
    filters,
    exportOptions: {
      filename: `Turni-${new Date().toISOString().substring(0,10)}`
    },
    mapData: (item) => {
      const partitionDate = item.partitionDate.toString()
      return {
        nome: item.userId?.displayName,
        email: item.userId?.email,
        note: item.note || '',
        data: partitionDate.substring(6, 8) + "/" + partitionDate.substring(4, 6) + "/" + partitionDate.substring(0, 4),
        orarioIngresso: item.startHr != null ? `${item.startHr.toString().padStart(2, '0')}:${item.startMin.toString().padStart(2, '0')}` : "NON TIMBRATO",
        orarioUscita: item.endHr != null ? `${item.endHr.toString().padStart(2, '0')}:${item.endMin.toString().padStart(2, '0')}` : "NON TIMBRATO",
        oreLavorate: item.totalHr || 0,
        minutiLavorati: item.totalMin  || 0,
        tempoInizioOrePausa: item.startHrPause != null ? item.startHrPause : "",
        tempoInizioMinPausa: item.startMinPause != null ? item.startMinPause : "",
        tempoFineOrePausa: item.endHrPause != null ? item.endHrPause : "",
        tempoFineMinPausa: item.endMinPause != null ? item.endMinPause : "",
        posizioneEntrata: item.startPosition?.lat && item.startPosition?.long ? `${item.startPosition.lat},${item.startPosition.long}` : "MANCANTE",
        posizioneUscita: item.endPosition?.lat && item.endPosition?.long ? `${item.endPosition.lat},${item.endPosition.long}` : "MANCANTE",
        urlPosizioni: (item.startPosition || item.endPosition) ? MAP_API_URL + '/shifts/maps/positions?' + appendQueryParams(item) : null
      };
    },
  });

  const showPositions = (startPosition: { lat: number, long: number }, endPosition: { lat: number, long: number }) => {
    if (startPosition) {
      setStartLat(startPosition.lat)
      setStartLong(startPosition.long)
    }
    if (endPosition) {
      setEndLat(endPosition.lat)
      setEndLong(endPosition.long)
    }

    show()
  }

  const showDrawer = () => {
    setOpenFilter(!openFilter);
  };

  const setFilterApplied = () => {
    setIsFilterApplied(!isFilterApplied)
  }

  const removeFilter = (field: string) => {
    form.setFieldsValue({ [field]: undefined })
    form.submit()
  }

  const generateFilterTags = (field: string) => {
    if (field === 'q') return form.getFieldValue(field)
    return form.getFieldValue(field)[0]?.$d?.toISOString().split('T')[0] + '/' + form.getFieldValue(field)[1]?.$d?.toISOString().split('T')[0]
  }

  function ChangeView({ center, zoom }: { center: any, zoom: any }) {
    const map = useMap();
    map.setView(center, zoom);
    return null;
  }
  
  return (
    <>
      <Helmet>
        <meta http-equiv="Content-Security-Policy" content="default-src *; style-src 'self' http://* 'unsafe-inline'; script-src 'self' http://* 'unsafe-inline' 'unsafe-eval'" />
      </Helmet>
      <Row gutter={[16, 16]} style={{ marginBottom: 14 }}>
        <Col xl={24} xs={24}>
          <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Space direction="horizontal" style={{ flexWrap: 'wrap' }}>
              <Button
                icon={<Avatar size={16} shape="square" src="/images/filter.png" />}
                style={{ width: "50px", border: '1px black', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)', backgroundColor: "#E8E8E8" }}
                onClick={() => showDrawer()}
              />
              {
                  Object.keys(form.getFieldsValue()).filter(f => form.getFieldValue(f) != null).map((f: string, idx: number) => (
                    <Tag closable color={['#5CE082', '#4BDBD6'][idx]} onClose={() => removeFilter(f)} style={{ height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      {generateFilterTags(f)}
                    </Tag>
                  ))
              }
            </Space>
            <ExportButton icon={<></>} children="Scarica" disabled={!(tableProps?.dataSource && tableProps.dataSource.length > 0)} style={{ order: '1px black', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)' }} onClick={triggerExport} loading={isLoading} />
          </Row>
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ paddingTop: "20px" }}>
        <Col xl={24} xs={24}>
          <Table {...tableProps}             locale={{
              emptyText: () => (
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "98px", backgroundColor: 'white', borderRadius: "30px" }}>
                  <Typography.Text strong style={{ opacity: "0.4", fontSize: "20px" }}>Nessun Turno disponibile</Typography.Text>
              </div>)
            }} rowKey="id" >
            <Table.Column
              key="userId"
              dataIndex="userId"
              title={t("shifts.fields.operator")}
              render={(value: string, _: IShift) => (
                <Space align="start">
                  <div className="product">
                    <Avatar
                      shape="square"
                      size={{
                        md: 40,
                        lg: 64,
                        xl: 64,
                        xxl: 64,
                      }}
                      src={_?.userId.avatarUrl ? _.userId.avatarUrl.url : "/images/man.png"}
                    />
                    <div className="product-text">
                      <Space direction="vertical">
                        <Typography.Text style={{ fontSize: 14, fontWeight: 800 }}>
                          {_?.userId.displayName}
                        </Typography.Text>
                        <Typography.Text style={{ fontSize: 13 }}>
                          {_?.userId.email}
                        </Typography.Text>
                      </Space>

                    </div>
                  </div>
                </Space>
              )}
            /> 
              <Table.Column
                key="note"
                dataIndex="note"
                align="center"
                title={"Note"}
                render={(value: string, record: IShift) => {
                  return (
                    <Space direction="horizontal" style={{ width: "100%" }}>
                      <Typography.Text style={{ lineBreak: 'strict', wordBreak: 'break-word' }}>{record.note}</Typography.Text>
                    </Space>
                  )
                }}
              />
            <Table.Column
              key="partitionDate"
              dataIndex="partitionDate"
              align="center"
              title={t("shifts.fields.entrance")}
              render={(value: any, record: any) => <DateField value={new Date(getFullDate(value, 0, 0))} locales={require("dayjs/locale/it")} format="DD-MM-YYYY" />}
              sorter={(a: number, b: number) => b - a}
            />
            <Table.Column
              key="startHr"
              dataIndex="startHr"
              align="center"
              title={t("shifts.fields.time")}
              render={(value: any, record: any) => record.startHr != null && record.endHr == null ? 
                `${record.startHr.toString().padStart(2, '0')}:${record.startMin.toString().padStart(2, '0')}` : 
                (
                  record.startHr != null && record.endHr != null ? (
                    `${record.startHr.toString().padStart(2, '0')}:${record.startMin.toString().padStart(2, '0')} - ${record.endHr.toString().padStart(2, '0')}:${record.endMin.toString().padStart(2, '0')}`
                  ) :
                  'NON TIMBRATO'
                )
                }
            />
            <Table.Column
              key="partitionDate.substring(0, 4)"
              align="center"
              dataIndex="totalHr"
              title={t("shifts.fields.total")}
              render={(value: any, record: IShift) => (
                record?.endHr != null ? (
                  <Typography.Text>{value + ' hr ' + record?.totalMin + ' min'}</Typography.Text>
                ) : ("--")
              )}
            />
            <Table.Column
              key="startHrPause"
              align="center"
              dataIndex="startHrPause"
              title={t("shifts.fields.pause")}
              render={(value: any, record: IShift) => (
                record?.startHrPause != null ? (
                  <Typography.Text>{record.startHrPause?.toString().padStart(2, '0') + ':' + record.startMinPause?.toString().padStart(2, '0') + '-' + record.endHrPause?.toString().padStart(2, '0') + ':' + record.endMinPause?.toString().padStart(2, '0')}</Typography.Text>
                ) : ("Non disponibile")
              )}
            />
            <Table.Column<IShift>
              title="Mappa"
              dataIndex="actions"
              align="center"
              fixed="right"
              render={(_, record: IShift) => (
                <Button
                  style={{ border: 'none' }}
                  disabled={record.startPosition == null && record.endPosition == null}
                  icon={<Avatar size={32} shape="square" src="/images/map.png" />}
                  onClick={() => showPositions(record.startPosition, record.endPosition)}
                />
              )}
            />
              <Table.Column<IShift>
                title={""}
                dataIndex="actions"
                fixed={"right"}
                align="center"
                render={(_, record: IShift) => (
                  <DeleteButton
                    className="zoom"
                    icon={<Avatar size={32} shape="square" src="/images/card-delete.png" />}
                    style={{ border: "none", marginBottom: "5px", marginLeft: "5px" }}
                    size="small"
                    hideText
                    recordItemId={record.id}
                  />
                )}
              />
          </Table>
        </Col>
      </Row>
      <ModalChangePassword userId={userId || ""} visible={modelOpen} setVisible={setModelOpen} />
      <Modal
        title={""}
        centered
        visible={visible}
        footer={null}
        onCancel={close}
        width={"1000px"}
      >
        <MapContainer center={[startLat, startLong]} zoom={13} scrollWheelZoom={true}>
          <ChangeView center={[startLat, startLong]} zoom={13} /> 
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {
            startLat && (
              <Marker position={[startLat, startLong]} icon={entranceIcon}>
                <Popup>
                  Entrata
                </Popup>
              </Marker>
            )
          }
          {
            endLat && (
              <Marker position={[endLat, endLong]} icon={exitIcon}>
                <Popup>
                  Uscita
                </Popup>
              </Marker>
            )
          }

        </MapContainer>
      </Modal>

      <Drawer
        title="Filtri"
        placement={'right'}
        closable={false}
        forceRender
        onClose={() => {
          if (Object.values(form.getFieldsValue()).every(el => el === undefined)) {
            form.resetFields()
          }
          showDrawer()
        }}
        visible={openFilter}
        headerStyle={{ backgroundColor: "#bababa" }}
        bodyStyle={{ backgroundColor: "#e9e9e9" }}
        key={'right'}
      >
        <Form layout="vertical" {...searchFormProps} form={form}>
          <Row gutter={16}>
            <Col xs={24} xl={24} md={24}>
              <Form.Item label={t("shifts.filter.userId")} name="q">
                <Input
                  allowClear
                  placeholder={t("shifts.filter.placeholderoperator")}
                  prefix={<Icons.SearchOutlined />}
                />
              </Form.Item>
            </Col>
            <Col xs={24} xl={24} md={24}>
              <Form.Item label={t("users.filter.createdAt.label")} name="createdAt">
                <RangePicker style={{ width: "100%" }} locale={require("dayjs/locale/it")} format="YYYY-MM-DD" defaultValue={[dayjs().hour(0).minute(0).second(0), dayjs().hour(23).minute(59).second(59)]}/>
              </Form.Item>
            </Col>
            <Col xs={24} xl={24} md={24} style={{ position: 'absolute', bottom: 0, left: 20, width: "90%" }}>
              <Form.Item label=" ">
                <Button style={{ width: "100%", backgroundColor: "#3f3c7e" }} htmlType="submit" type="primary" onClick={() => {
                  setFilterApplied()
                  showDrawer()
                }}>
                  {t("users.filter.submit")}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
};