import { useTranslate, useApiUrl, useList, useGetIdentity } from "@pankod/refine-core";

import {
  Edit,
  Drawer,
  DrawerProps,
  Form,
  FormProps,
  Input,
  InputNumber,
  Radio,
  Select,
  Space,
  ButtonProps,
  Avatar,
  Typography,
  Upload,
  Grid,
  getValueFromEvent,
  Button,
  Icons,
  UploadFile,
} from "@pankod/refine-antd";

import { useState } from "react";
import { TOKEN_KEY } from "authProvider";

const { Text } = Typography;

type EditUserProps = {
  drawerProps: DrawerProps;
  formProps: FormProps;
  saveButtonProps: ButtonProps;
};

export const EditHeaderUser: React.FC<EditUserProps> = ({
  drawerProps,
  formProps,
  saveButtonProps,
}) => {
  const t = useTranslate();
  const apiUrl = useApiUrl();
  const breakpoint = Grid.useBreakpoint();
  const [passwordShown, setPasswordShown] = useState(false);
  const [fileList, setDocumentList] = useState<UploadFile[]>([
    {
      uid: "",
      name: "",
      status: "done",
      url: "",
    },
  ]);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  return (
    <Drawer
      {...drawerProps}
      width={breakpoint.sm ? "500px" : "100%"}
      bodyStyle={{ padding: 0 }}
      zIndex={1001}
    >
      <Edit
        saveButtonProps={{
          ...saveButtonProps,
          style: { backgroundColor: "#3f3c7e", border: 'none' }
        }}
        pageHeaderProps={{ extra: null }}
        resource="users"
        breadcrumb={""}
        goBack={null}
        footerButtonProps={{
          style: { backgroundColor: "#3f3c7e", border: 'none' }
        }}
      >
        <Form {...formProps} layout="vertical">
          <Form.Item label={t("users.fields.avatar.label")}>
            <Form.Item
              name="avatarUrl"
              valuePropName="fileList"
              getValueFromEvent={getValueFromEvent}
              noStyle
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Upload.Dragger
                name="file"
                action={`${apiUrl}/images/upload`}
                headers={{
                  "x-token": `${localStorage.getItem(TOKEN_KEY)}`,
                }}
                listType="picture"
                maxCount={1}
                accept=".png"
                fileList={fileList}
                onChange={(info) => {
                  let newDocumentList = [...info.fileList];

                  newDocumentList = newDocumentList.slice(-2);

                  newDocumentList = newDocumentList.map((file) => {
                    if (file.response) {
                      file.url = file.response.url;
                    }
                    return file;
                  });

                  setDocumentList(newDocumentList);
                }}
              >
                <Space direction="vertical" size={2}>
                  <Avatar
                    style={{
                      width: "100%",
                      height: "100%",
                      maxWidth: "256px",
                    }}
                    src={
                      (formProps?.initialValues?.avatarUrl &&
                        Array.isArray(formProps?.initialValues?.avatarUrl) &&
                        formProps?.initialValues?.avatarUrl[0]?.url) ||
                        "/images/man.png"
                    }
                    alt="Store Location"
                  />
                  <Text
                    style={{
                      fontWeight: 800,
                      fontSize: "16px",
                      marginTop: "8px",
                    }}
                  >
                    {t("users.fields.avatar.description")}
                  </Text>
                </Space>
              </Upload.Dragger>
            </Form.Item>
          </Form.Item>
          <Form.Item
            label={t("users.fields.firstName")}
            name="displayName"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t("users.fields.role.label")}
            name="role"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Select
              options={[
                {
                  label: t("users.fields.role.admin"),
                  value: t("users.fields.role.admin"),
                },
                {
                  label: t("users.fields.role.operator"),
                  value: t("users.fields.role.operator"),
                },
              ]}
              disabled={true}
            />
          </Form.Item>
          <Form.Item
            name="email"
            label={t("pages.login.fields.email", "Email")}
            rules={[
              { required: true },
              {
                type: "email",
                message: t(
                  "pages.login.errors.validEmail",
                  "Invalid email address"
                ),
              },
            ]}
          >
            <Input
              size="middle"
              disabled={true}
              placeholder={t("pages.login.fields.email", "Email")}
            />
          </Form.Item>
          <Form.Item
            name="password"
            label={t("pages.login.fields.password", "Password")}
            rules={[{ required: false }]}
            style={{ marginBottom: "12px" }}
          >
            <Input
              type={passwordShown ? "text" : "password"}
              placeholder="●●●●●●●●"
              size="small"
              prefix={
                <Button
                  key={"passwordRefresh"}
                  type="ghost"
                  block
                  icon={<Icons.EyeFilled />}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: "none",
                  }}
                  onClick={() => togglePassword()}
                >
                  {" "}
                </Button>
              }
            />
          </Form.Item>
          <Typography.Text style={{ fontSize: "12px" }}>
            {t("users.filter.passwordDescription")}
          </Typography.Text>
        </Form>
      </Edit>
    </Drawer>
  );
};
