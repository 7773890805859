import {
  useTranslate,
  IResourceComponentsProps,
  CrudFilters,
  HttpError,
  useGetIdentity,
  useExport
} from "@pankod/refine-core";

import {
  Table,
  Avatar,
  useTable,
  Input,
  Icons,
  Form,
  DatePicker,
  Button,
  Row,
  Col,
  useDrawerForm,
  Space,
  DeleteButton,
  EditButton,
  Tag,
  Typography,
  ShowButton,
  ExportButton,
  FormProps,
  Select,
  Card,
  List
} from "@pankod/refine-antd";

import { IMember, IMemberFilterVariables } from "../interfaces";
import { CreateUser, EditUser } from "components";
import { activeStatusColor } from "utils";

import "./style.less";

const { Text } = Typography;

export const UserList: React.FC<IResourceComponentsProps> = () => {
  const userLogged = useGetIdentity()
  const { tableProps, sorter, filters, searchFormProps } = useTable<
    IMember,
    HttpError,
    IMemberFilterVariables
  >({
    initialSorter: [
      {
        field: "createdAt",
        order: "desc",
      },
    ],
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { q, createdAt, role, isActive } = params;

      filters.push({
        field: "q",
        operator: "eq",
        value: q,
      });

      filters.push(
        {
          field: "createdAt",
          operator: "gte",
          value: createdAt
            ? createdAt[0].startOf("day").toISOString()
            : undefined,
        },
        {
          field: "createdAt",
          operator: "lte",
          value: createdAt
            ? createdAt[1].endOf("day").toISOString()
            : undefined,
        }
      );

      filters.push({
        field: "role",
        operator: "eq",
        value: role,
      });

      filters.push({
        field: "isActive",
        operator: "eq",
        value: isActive,
      });

      return filters;
    },
    syncWithLocation: false,
  });

  const { isLoading, triggerExport } = useExport<IMember>({
    sorter,
    filters,
    exportOptions: {
      filename: `membri-${new Date().toISOString().substring(0, 10)}`
    },
    mapData: (item) => {
      return {
        id: item.id,
        Nome: item.displayName,
        Email: item.email,
        Attivo: item.isActive,
        Role: item.role,
        Tel: item.phone || ''
      };
    },
  });

  const Export: React.FC = () => (
    <ExportButton children="Esporta" disabled={!(tableProps?.dataSource && tableProps.dataSource.length > 0)} style={{ backgroundColor: 'white' }} onClick={triggerExport} loading={isLoading} />
  );

  const {
    drawerProps: editDrawerProps,
    formProps: editFormProps,
    saveButtonProps: editSaveButtonProps,
    show: editShow,
  } = useDrawerForm<IMember>({
    action: "edit",
    resource: "users",
    redirect: false,
  });

  const {
    drawerProps: createDrawerProps,
    formProps: createFormProps,
    saveButtonProps: createSaveButtonProps,
    show: createShow,
  } = useDrawerForm<IMember>({
    action: "create",
    resource: "users",
    redirect: false,
  });

  const t = useTranslate();

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xl={20} xs={24}>
          <List
            title={<></>}
            pageHeaderProps={{
              extra: (
                <Space>
                  <Export />
                  <Button
                    icon={<Icons.PlusCircleTwoTone />}
                    style={{ width: "50px" }}
                    onClick={() => createShow()}
                  />
                </Space>
              ),

            }}
          >
            <Table {...tableProps} 
              locale={{
                emptyText: () => (
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "98px", backgroundColor: 'white', borderRadius: "30px" }}>
                    <Typography.Text strong style={{ opacity: "0.4", fontSize: "20px" }}>Nessun utente presente</Typography.Text>
                  </div>)
              }}
              rowKey="id" style={{ backgroundColor: "transparent" }}>
              <Table.Column
                key="userId"
                dataIndex="userId"
                title={t("users.fields.operator")}
                render={(value: string, _: IMember) => (
                  <Space align="start" style={{ marginLeft: "5px" }}>
                    <div className="product">
                      <Avatar
                        shape="square"
                        size={{
                          md: 25,
                          lg: 55,
                          xl: 55,
                          xxl: 55,
                        }}
                        src={_?.avatarUrl[0]?.url || "/images/man.png"}
                      />
                      <div className="product-text">
                        <Text style={{ fontSize: 14, fontWeight: 800 }}>
                          {_?.displayName}
                        </Text>
                        <Tag style={{ width: "100%", display: "flex", justifyContent: "center", paddingTop: "5px" }} color={_?.role === "ADMIN" ? "#CBF073" : "#EBD694"}>{t("users.fields.role." + _?.role?.toLocaleLowerCase())}</Tag>
                      </div>
                    </div>
                  </Space>
                )}
              />
              <Table.Column
                key="isAppLoginActive"
                dataIndex="isAppLoginActive"
                align="center"
                title={t("users.fields.licenseApp.label")}
                render={(value) =>
                  <Tag color={activeStatusColor(value)?.color}>{value === true ? t("users.fields.licenseApp.true") : t("users.fields.licenseApp.false")}</Tag>
                }
              />
              <Table.Column
                key="isActive"
                dataIndex="isActive"
                align="center"
                title={t("users.fields.isActive.label")}
                render={(value) =>
                  <Tag color={activeStatusColor(value)?.color}>{value === true ? t("users.fields.isActive.true") : t("users.fields.isActive.false")}</Tag>
                }
              />
              <Table.Column
                key="email"
                dataIndex="email"
                align="center"
                title={t("users.fields.email")}
              />
              <Table.Column<IMember>
                title={""}
                dataIndex="actions"
                align="center"
                render={(_, record: IMember) => (
                  <Space align="start">
                    <ShowButton
                      className="zoom"
                      style={{ border: "none", marginBottom: "5px", marginLeft: "5px" }}
                      hideText
                      size="small"
                      icon={<Avatar size={28} shape="square" src="/images/eye.png" />}
                      recordItemId={record.id}
                    />
                    <EditButton
                      className="zoom"
                      icon={<Avatar size={28} shape="square" src="/images/pencil.png" />}
                      style={{ border: "none", marginBottom: "5px", marginLeft: "5px" }}
                      hideText
                      size="small"
                      recordItemId={record.id}
                      onClick={() => editShow(record.id)}
                    />
                    <DeleteButton
                      className="zoom"
                      disabled={record?.id === userLogged?.data?.id}
                      icon={<Avatar size={28} shape="square" src="/images/card-delete.png" />}
                      style={{ border: "none", marginBottom: "5px", marginLeft: "5px" }}
                      size="small"
                      hideText
                      recordItemId={record.id}
                    />
                  </Space>
                )}
              />
            </Table>
          </List>
        </Col>
        <Col xl={4} lg={24} xs={24}>
          <Card title={""} style={{ border: '1px black' }}>
            <Filter formProps={searchFormProps} />
          </Card>
        </Col>
      </Row>
      <EditUser
        drawerProps={editDrawerProps}
        formProps={editFormProps}
        saveButtonProps={editSaveButtonProps}
      />
      <CreateUser
        drawerProps={createDrawerProps}
        formProps={createFormProps}
        saveButtonProps={createSaveButtonProps}
      />
    </>
  );
};


const Filter: React.FC<{ formProps: FormProps }> = (props) => {
  const t = useTranslate();

  const { RangePicker } = DatePicker;

  return (
    <Form layout="vertical" {...props.formProps}>
      <Row gutter={[10, 0]} align="bottom">
        <Col xs={24} xl={24} md={12}>
          <Form.Item label={t("users.filter.search.label")} name="q">
            <Input
              placeholder={t("users.filter.search.placeholder")}
              prefix={<Icons.SearchOutlined />}
            />
          </Form.Item>
        </Col>
        <Col xs={24} xl={24} md={12}>
          <Form.Item label={t("users.filter.createdAt.label")} name="createdAt">
            <RangePicker style={{ width: "100%" }} locale={require("dayjs/locale/it")} />
          </Form.Item>
        </Col>
        <Col xs={24} xl={24} md={8}>
          <Form.Item label={t("users.filter.role.label")} name="role">
            <Select
              allowClear
              placeholder={t("users.filter.role.placeholder")}
              options={[
                {
                  label: t("users.filter.role.admin"),
                  value: "ADMIN",
                },
                {
                  label: t("users.filter.role.appuser"),
                  value: "UTENTEAPP",
                },
              ]}
            />
          </Form.Item>
        </Col>
        <Col xs={24} xl={24} md={8}>
          <Form.Item label={t("users.filter.isActive.label")} name="isActive">
            <Select
              allowClear
              placeholder={t("users.filter.isActive.placeholder")}
              options={[
                {
                  label: t("users.filter.isActive.true"),
                  value: "true",
                },
                {
                  label: t("users.filter.isActive.false"),
                  value: "false",
                }
              ]}
            />
          </Form.Item>
        </Col>
        <Col xs={24} xl={24} md={8}>
          <Form.Item>
            <Button style={{ width: "100%", backgroundColor: "#3f3c7e" }} htmlType="submit" type="primary">
              {t("users.filter.submit")}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};