import { useTranslate, useApiUrl, useGetIdentity } from "@pankod/refine-core";

import {
  Edit,
  Drawer,
  DrawerProps,
  Form,
  FormProps,
  Input,
  Radio,
  Select,
  Space,
  ButtonProps,
  Avatar,
  Typography,
  Upload,
  Grid,
  getValueFromEvent,
  Button,
  Icons,
  UploadFile,
} from "@pankod/refine-antd";
import { TimePicker } from 'antd';
import { ModifierKey, useEffect, useState } from "react";
import InputMask from 'react-input-mask';
import { TOKEN_KEY } from "authProvider";
import moment from "moment";
import { error } from "console";

const { Text } = Typography;

type EditUserProps = {
  drawerProps: DrawerProps;
  formProps: FormProps;
  saveButtonProps: ButtonProps;
};

export const EditUser: React.FC<EditUserProps> = ({
  drawerProps,
  formProps,
  saveButtonProps,
}) => {
  const t = useTranslate();
  const apiUrl = useApiUrl();
  const breakpoint = Grid.useBreakpoint();
  const userLogged = useGetIdentity()
  const [passwordShown, setPasswordShown] = useState(false);
  const [fileList, setDocumentList] = useState<UploadFile[]>([
    {
      uid: "",
      name: "",
      status: "done",
      url: "",
    },
  ]);
  const [form] = Form.useForm();

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  useEffect(() => {
    if (formProps?.initialValues) {  
      let totalStartPause = null
      let totalEndPause = null

      if (formProps.initialValues.startHrPause != null && formProps.initialValues.startMinPause != null) {
        let tsp = moment()

        tsp.set("hour", formProps.initialValues.startHrPause)
        tsp.set("minute", formProps.initialValues.startMinPause)

        totalStartPause = tsp

        tsp = moment()

        tsp.set("hour", formProps.initialValues.endHrPause)
        tsp.set("minute", formProps.initialValues.endMinPause)

        totalEndPause = tsp
      }
      
      form.setFieldsValue({
        ...formProps.initialValues,
        ...totalStartPause && { totalStartPause },
        ...totalEndPause && { totalEndPause },
      });
    }
  }, [formProps.initialValues])

  return (
    <Drawer
      {...drawerProps}
      width={breakpoint.sm ? "500px" : "100%"}
      bodyStyle={{ padding: 0 }}
      zIndex={1001}
    >
      <Edit
        saveButtonProps={{
          ...saveButtonProps,
          onClick: () => {
            const totalStartPause = form.getFieldValue("totalStartPause")
            const totalEndPause = form.getFieldValue("totalEndPause")

            if ((totalStartPause != null && totalEndPause == null) 
                || (!totalStartPause &&  totalEndPause != null) ||
                    (totalStartPause != null && totalEndPause != null && (
                      totalStartPause.diff(totalEndPause, "seconds") > 0
                    ))) {
                      return
                    }
            form.setFieldsValue({
              isAppLoginActive: form.getFieldValue("isAppLoginActive") || false,
              startHrPause: totalStartPause?.hour() != null ? totalStartPause.hour() :  null,
              startMinPause: totalStartPause?.minute() != null ?  totalStartPause.minute()  : null,
              endHrPause: totalEndPause?.hour() != null ? totalEndPause.hour()  : null,
              endMinPause: totalEndPause?.minute() != null ? totalEndPause.minute() : null,
            })

            form.submit()
          },
          style: { backgroundColor: "#3f3c7e", border: 'none' }
        }}
        pageHeaderProps={{ extra: null }}
        resource="users"
      >
        <Form {...formProps} layout="vertical" form={form}>
          <Form.Item name="startHrPause" hidden />
          <Form.Item name="startMinPause" hidden />
          <Form.Item name="endHrPause" hidden />
          <Form.Item name="endMinPause" hidden />
          <Form.Item label={t("users.fields.avatar.label")}>
            <Form.Item
              name="avatarUrl"
              valuePropName="fileList"
              getValueFromEvent={getValueFromEvent}
              noStyle
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Upload.Dragger
                name="file"
                action={`${apiUrl}/images/upload`}
                headers={{
                  "x-token": `${localStorage.getItem(TOKEN_KEY)}`,
                }}
                style={{ borderRadius: "30px" }}
                listType="picture"
                maxCount={1}
                accept=".png"
                fileList={fileList}
                onChange={(info) => {
                  let newDocumentList = [...info.fileList];

                  newDocumentList = newDocumentList.slice(-2);

                  newDocumentList = newDocumentList.map((file) => {
                    if (file.response) {
                      file.url = file.response.url;
                    }
                    return file;
                  });

                  setDocumentList(newDocumentList);
                }}
              >
                <Space direction="vertical" size={2}>
                  <Avatar
                    style={{
                      width: "100%",
                      height: "100%",
                      maxWidth: "256px",
                    }}
                    src={
                      (formProps?.initialValues?.avatarUrl &&
                        Array.isArray(formProps?.initialValues?.avatarUrl) &&
                        formProps?.initialValues?.avatarUrl[0]?.url) ||
                      "/images/man.png"
                    }
                    alt="Store Location"
                  />
                  <Text
                    style={{
                      fontWeight: 800,
                      fontSize: "16px",
                      marginTop: "8px",
                    }}
                  >
                    {t("users.fields.avatar.description")}
                  </Text>
                </Space>
              </Upload.Dragger>
            </Form.Item>
          </Form.Item>
          <Form.Item
            label={t("users.fields.firstName")}
            name="displayName"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t("users.fields.role.label")}
            name="role"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Select
              options={[
                {
                  label: t("users.fields.role.admin"),
                  value: "ADMIN",
                },
                {
                  label: t("users.fields.role.operator"),
                  value: "OPERATOR",
                },
              ]}
              disabled={true}
            />
          </Form.Item>
          <Form.Item
            name="email"
            label={t("pages.login.fields.email", "Email")}
            rules={[
              { required: true },
              {
                type: "email",
                message: t(
                  "pages.login.errors.validEmail",
                  "Invalid email address"
                ),
              },
            ]}
          >
            <Input
              size="middle"
              placeholder={t("pages.login.fields.email", "Email")}
            />
          </Form.Item>
          <Form.Item
            name="phone"
            label={t("users.fields.gsm", "Phone")}
            rules={[{ required: false }]}
          >
            <InputMask mask="(999) 999 99 99">
              {/* 
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore */}
              {(props: InputProps) => (
                <Input {...props} />
              )}
            </InputMask>
          </Form.Item>
          <Form.Item
            label={t("users.fields.isActive.label")}
            name="isActive"
            rules={[{ required: formProps?.initialValues?.id !== userLogged?.data?.id }]}
          >
            <Radio.Group
              disabled={formProps?.initialValues?.id === userLogged?.data?.id}
            >
              <Radio value={true}>{t("status.enable")}</Radio>
              <Radio value={false}>{t("status.disable")}</Radio>
            </Radio.Group>
          </Form.Item>
          <Space direction="vertical">
            <Typography.Text strong>Pausa</Typography.Text>
            <Space direction="horizontal">
              <Form.Item
                name="totalStartPause"
                rules={[{
                  required: false, async validator(rule, value) {
                    const totalEndPause = form.getFieldValue("totalEndPause")
                    const t = form.getFieldInstance("totalEndPause")

                    if (value != null && totalEndPause != null) {
                      form.setFields([
                        {
                          name: "totalEndPause",
                          value: totalEndPause,
                          errors: []
                        }
                      ])
                    }

                    if (value == null && totalEndPause != null) {
                      form.setFields([
                        {
                          name: "totalEndPause",
                          value: totalEndPause,
                          errors: ["Orario inizio pausa errato"]
                        }
                      ])
                    }

                    if (!form.isFieldValidating("totalEndPause") && value && value.diff(totalEndPause, 'seconds') > 0 ) return Promise.reject("Orario inizio pausa errato")


                    if ((value === null && totalEndPause === null) ||
                          (value && totalEndPause)) {
                      return Promise.resolve()
                    } 
                    if (value && value.diff(totalEndPause, 'seconds') > 0) return Promise.reject("Orario inizio pausa errato")
                    
                    return Promise.resolve()
                  },
                }]}
              >
                <TimePicker placeholder="Inizio pausa" format={'HH:mm'} />
              </Form.Item>
              <Form.Item
                name="totalEndPause"
                rules={[{
                  required: false, async validator(rule, value) {
                    const totalStartPause = form.getFieldValue("totalStartPause")
       
                    if (value != null && totalStartPause != null) {
                      form.setFields([
                        {
                          name: "totalStartPause",
                          value: totalStartPause,
                          errors: []
                        }
                      ])
                    }

                    if (!totalStartPause && value !== null) return Promise.reject("Inserire inizio pausa")
                    if (totalStartPause && value === null) return Promise.reject("Inserire fine pausa")
                    if (!form.isFieldValidating("totalStartPause") && value && value.diff(totalStartPause, 'seconds') <= 0 ) return Promise.reject("Orario inizio pausa errato")

                    return Promise.resolve()
                  },
                }]}
              >
                <TimePicker placeholder="Fine pausa" format={'HH:mm'} />
              </Form.Item>
            </Space>
          </Space>
          <Form.Item
            name="password"
            label={t("pages.login.fields.password", "Password")}
            rules={[{ required: false }, { min: 8 }]}
            style={{ marginBottom: "12px", width: "100%" }}
          >
            <Input
              type={passwordShown ? "text" : "password"}
              placeholder="●●●●●●●●"
              size="small"
              prefix={
                <Button
                  key={"passwordRefresh"}
                  type="ghost"
                  block
                  icon={<Icons.EyeFilled />}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: "none",
                  }}
                  onClick={() => togglePassword()}
                >
                  {" "}
                </Button>
              }
            />
          </Form.Item>
          <Typography.Text>
            {t("users.filter.passwordDescription")}
          </Typography.Text>
        </Form>
      </Edit>
    </Drawer>
  );
};
