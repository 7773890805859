import { logoName } from "utils";
import "./style.less";
import { Space, Typography } from "@pankod/refine-antd";

type TitleProps = {
    collapsed: boolean;
};

export const Title: React.FC<TitleProps> = ({ collapsed }) => {
    const logo = logoName()
    return (
        <div className="logo">
            {collapsed ? (
                <img src={`/images/${logo}`} alt="GoWork" style={{ width: "24px", borderRadius: "20px" }} />
            ) : (
                <Space direction="vertical">
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><img src={`/images/${logo}`} alt="GoWork" style={{ width: "64px", borderRadius: "20px" }} /></div>
                    <Typography.Title style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'white', fontSize: '24px' }}>GoWork</Typography.Title>
                </Space>
            )}
        </div>
    );
};
