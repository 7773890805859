
import {
    CloseCircleOutlined,
    FormOutlined,
    MoreOutlined,
} from "@ant-design/icons";
import {
    Avatar,
    Card,
    Divider,
    InputNumber,
    Dropdown,
    Menu,
    Typography,
} from "antd";

import { IEvent } from "../../interfaces";
import { BaseKey, useTranslate } from "@pankod/refine-core";
import { Col, DateField, NumberField, Row } from "@pankod/refine-antd";

const { Text, Paragraph } = Typography;

type EventItemProps = {
    item: IEvent;
    updateStock?: (changedValue: number, clickedItem: IEvent) => void;
    editShow: (id?: BaseKey) => void;
};

export const EventItem: React.FC<EventItemProps> = ({
    item,
    updateStock,
    editShow,
}) => {
    const t = useTranslate();

    return (
        <Card
            style={{
                margin: "8px",
                opacity: !item.isActive ? 0.5 : 1,
                border: '5px black', 
                boxShadow: '0 2px 10px rgba(0, 0, 0, 3.5)'
            }}
            bodyStyle={{ height: "500px" }}
        >
            <div style={{ position: "absolute", top: "-10px", right: "-10px" }}>

                <div style={{ textAlign: "center" }}>
                    <Avatar
                        shape="square"
                        size={64}
                        src={`/images/${item.type.toLocaleLowerCase()}.png`}
                        alt={item.type}
                    />
                    <Dropdown
                        overlay={
                            <Menu mode="vertical">
                                {updateStock && (
                                    <Menu.Item
                                        key="1"
                                        disabled={!item.isActive}
                                        style={{
                                            fontWeight: 500,
                                        }}
                                        icon={
                                            <CloseCircleOutlined
                                                style={{
                                                    color: "red",
                                                }}
                                            />
                                        }
                                        onClick={() => updateStock(0, item)}
                                    >
                                        {t("stores.buttons.outOfStock")}
                                    </Menu.Item>
                                )}
                                <Menu.Item
                                    key="2"
                                    style={{
                                        fontWeight: 500,
                                    }}
                                    icon={
                                        <FormOutlined
                                            style={{
                                                color: "green",
                                            }}
                                        />
                                    }
                                    onClick={() => editShow(item.id)}
                                >
                                    {t("stores.buttons.edit")}
                                </Menu.Item>
                            </Menu>
                        }
                        trigger={["click"]}
                    >
                        <MoreOutlined
                            style={{
                                fontSize: 24,
                            }}
                        />
                    </Dropdown> 
                </div>

            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100%",
                }}
            >
{/*                 <div style={{ textAlign: "center" }}>
                    <Avatar
                        shape="square"
                        size={64}
                        src={`/images/${item.type.toLocaleLowerCase()}.png`}
                        alt={item.type}
                    />
                </div>
                <Divider /> */}
                <Paragraph
                    ellipsis={{ rows: 2, tooltip: true }}
                    style={{
                        fontSize: "18px",
                        fontWeight: 700,
                    }}
                >
                    {item.title}
                </Paragraph>
                <Paragraph
                    ellipsis={{ rows: 3, tooltip: true }}
                    style={{ marginBottom: "8px" }}
                >
                    {item.body}
                </Paragraph>
                <Text
                    className="item-id"
                    style={{
                        fontSize: "15px",
                        fontWeight: 200
                    }}
                >
                    {item.summary}
                </Text>
                <DateField value={new Date()} />
            </div>
        </Card>
    );
};