import { useTranslate } from "@pankod/refine-core";

import {
    Drawer,
    DrawerProps,
    Form,
    FormProps,
    Input,
    Radio,
    ButtonProps,
    Grid,
    Create,
    Select,
    DatePicker,
    Avatar,
    Space,
    InputNumber,
    Typography,
} from "@pankod/refine-antd";
import { IMember } from "interfaces";
import { useEffect } from "react";

type CreateMessageProps = {
    drawerProps: DrawerProps;
    formProps: FormProps;
    saveButtonProps: ButtonProps;
    users: Array<IMember>
};

export const CreateMessage: React.FC<CreateMessageProps> = ({
    drawerProps,
    formProps,
    saveButtonProps,
    users
}) => {
    const t = useTranslate();
    const breakpoint = Grid.useBreakpoint();
    const [form] = Form.useForm();

    useEffect(() => {
        if (drawerProps.visible === true) {
            form.resetFields()
        }
    }, [drawerProps.visible])
    
    return (
        <Drawer
            {...drawerProps}
            width={breakpoint.sm ? "500px" : "100%"}
            bodyStyle={{ padding: 0 }}
            zIndex={1001}
        >
            <Create
                saveButtonProps={{
                    ...saveButtonProps,
                    onClick: () => form.submit(),
                    style: { backgroundColor: "#3f3c7e" }
                }}
                pageHeaderProps={{ extra: null }}
                resource="notices"
            >
                <Form {...formProps} layout="vertical" form={form}>
                    <Form.Item
                        label={t("notices.fields.title")}
                        name="title"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={t("news.fields.type.label")}
                        name="type"
                        rules={[
                        {
                            required: true,
                        },
                        ]}
                    >
                        <Select
                        options={[
                            {
                                label: t("notices.fields.type.event"),
                                value: t("notices.fields.type.event"),
                            },
                            {
                                label: t("notices.fields.type.info"),
                                value: t("notices.fields.type.info"),
                            },
                            {
                                label: t("notices.fields.type.warning"),
                                value: t("notices.fields.type.warning"),
                            },
                            {
                                label: t("notices.fields.type.news"),
                                value: t("notices.fields.type.news"),
                            },
                            {
                                label: t("notices.fields.type.alarm"),
                                value: t("notices.fields.type.alarm"),
                            },
                        ]}
                        />
                    </Form.Item>
                    <Form.Item
                        label={t("notices.fields.body")}
                        name="body"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input.TextArea style={{ height: "100px" }} />
                    </Form.Item>
                    <Form.Item
                        label={t("notices.fields.summary")}
                        name="summary"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input.TextArea style={{ height: "100px" }} />
                    </Form.Item>
                    <Form.Item
                        label={t("notices.fields.operators")}
                        name='sentByAppUserAt'
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Select
                            options={users?.map((_: IMember) => ({
                                label: (
                                    <span style={{ display: "inline-flex" }}>
                                        {_.displayName}
                                        <Avatar
                                            size={{
                                                md: 20,
                                                lg: 20,
                                                xl: 20,
                                                xxl: 20,
                                            }}
                                            src={_?.avatarUrl[0]?.url || "/images/profile.png"}
                                        />
                                    </span>
                                ),
                                value: _.id
                            })) ?? []}
                        />
                    </Form.Item>
                    <Form.Item
                        label={t("users.fields.isActive.label")}
                        name="isActive"
                        rules={[{ required: true }]}
                        initialValue={false}
                    >
                        <Radio.Group>
                            <Radio value={true}>{t("status.enable")}</Radio>
                            <Radio value={false}>{t("status.disable")}</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        label={t("notices.fields.isActiveFromDate")}
                        name="isActiveFromDate"
                        rules={[
                            {
                                required: false,
                                async validator(rule, value) {
                                    if (!value || !value.$d) return Promise.resolve()
                                    const isActiveToDate = form.getFieldValue("isActiveToDate")
                                    if (!isActiveToDate || !isActiveToDate.$d) return Promise.resolve()
                                    if (value.$d.getTime() > isActiveToDate.$d.getTime()) return Promise.reject("Non può maggiore della data a")
                                    return Promise.resolve()
                                },
                            },
                        ]}
                    >
                        <DatePicker format= 'DD/MM/YYYY' style={{ width: "60%" }} locale={require("dayjs/locale/it")}/>
                    </Form.Item>
                    <Form.Item
                        label={t("notices.fields.isActiveToDate")}
                        name="isActiveToDate"
                        rules={[
                            {
                                required: false,
                                async validator(rule, value) {
                                    if (!value || !value.$d) return Promise.resolve()
                                    const isActiveFromDate = form.getFieldValue("isActiveFromDate")
                                    if (!isActiveFromDate || !isActiveFromDate.$d) return Promise.resolve()
                                    if (value.$d.getTime() <= isActiveFromDate.$d.getTime()) return Promise.reject("Non può esssre uguale o inferiore alla data da")
                                    return Promise.resolve()
                                },
                            },
                        ]}
                    >
                        <DatePicker format= 'DD/MM/YYYY' style={{ width: "60%" }} locale={require("dayjs/locale/it")}/>
                    </Form.Item>
                    <Space>
                        <Form.Item
                            label={t("notices.fields.lat")}
                            name="lat"
                            rules={[
                                {
                                    required: false,
                                    type: "number",
                                    async validator(rule, value) {
                                        const long =  form?.getFieldValue("long")

                                        if (value && !long) return Promise.reject()
                                        return Promise.resolve()
                                    },
                                },
                            ]}
                        >
                        <InputNumber style={{ width: "180px" }}/>
                        </Form.Item>
                        <Form.Item
                            label={t("notices.fields.long")}
                            name="long"
                            rules={[
                                {
                                    required: false,
                                    type: "number",
                                    async validator(rule, value) {
                                        const lat =  form?.getFieldValue("lat")
                                        
                                        if (value && !lat) return Promise.reject()
                                        return Promise.resolve()
                                    },
                                },
                            ]}
                        >
                            <InputNumber style={{ width: "180px" }}/>
                        </Form.Item>
                    </Space>
                    <Typography.Paragraph type="secondary">Messaggio visibile solo per gli utenti all’interno dell’area specificata.</Typography.Paragraph>
                </Form>
            </Create>
        </Drawer>
    );
};
