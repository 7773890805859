import {
    useTranslate,
    IResourceComponentsProps,
    CrudFilters,
    HttpError
  } from "@pankod/refine-core";
  
  import {
    Table,
    useTable,
    Card,
    Form,
    DatePicker,
    Select,
    FormProps,
    Row,
    Col,
    Tag,
    Button,
    EditButton,
    DeleteButton,
    Space,
    useDrawerForm,
    Avatar,
    List,
    Typography,
    DateField,
    Drawer,
    Input,
    Icons,
  } from "@pankod/refine-antd";
  import { IEvent, IEventFilterVariables } from "interfaces";
  import { activeStatusColor } from "utils";
  import "./style.less";
  import { CreateNews, EditNews } from "components/news";
  import { useState } from "react";
  import type { FilterConfirmProps } from 'antd/es/table/interface';
  
  export const NewsList: React.FC<IResourceComponentsProps> = () => {
    const { tableProps, searchFormProps } = useTable<
      IEvent,
      HttpError,
      IEventFilterVariables
    >({
      initialSorter: [
        {
          field: "createdAt",
          order: "desc",
        },
      ],
      onSearch: (params) => {
        const filters: CrudFilters = [];
        const { q, isActive, createdAt, type, rangeCreatedAt } = params;
  
        filters.push({
          field: "q",
          operator: "eq",
          value: q,
        });
  
        filters.push(
          {
            field: "createdAt",
            operator: "gte",
            value: createdAt
              ? createdAt[0].startOf("day").toISOString()
              : undefined,
          },
          {
            field: "createdAt",
            operator: "lte",
            value: createdAt
              ? createdAt[1].endOf("day").toISOString()
              : undefined,
          }
        );
  
        filters.push({
          field: "rangeCreatedAt",
          operator: "eq",
          value: rangeCreatedAt,
        });
        
  
        filters.push({
          field: "isActive",
          operator: "eq",
          value: isActive,
        });
  
        filters.push({
          field: "type",
          operator: "eq",
          value: type,
        });
  
        return filters;
      },
      syncWithLocation: false,
    });
  
    const {
      drawerProps: editDrawerProps,
      formProps: editFormProps,
      saveButtonProps: editSaveButtonProps,
      show: editShow
    } = useDrawerForm<IEvent>({
      action: "edit",
      resource: "news",
      redirect: false,
    });
  
    const {
      drawerProps: createDrawerProps,
      formProps: createFormProps,
      saveButtonProps: createSaveButtonProps,
      show: createShow
    } = useDrawerForm<IEvent>({
      action: "create",
      resource: "news",
      redirect: false,
    });
    const { RangePicker } = DatePicker;
    const t = useTranslate();
    const [openFilter, setOpenFilter] = useState(false);
    const [form] = Form.useForm();
    const [isFilterApplied, setIsFilterApplied] = useState(false)
  
    const remappedTagsFilter = (field: string) => {
      if (field === 'type') return form.getFieldValue(field)
      if (field === 'isActive') {
        if (form.getFieldValue(field) === "true") return t("notices.filter.isActive.true")
        return t("notices.filter.isActive.false")
      }
  
      return form.getFieldValue(field)[0]?.$d?.toISOString().split('T')[0] + '/' + form.getFieldValue(field)[1]?.$d?.toISOString().split('T')[0]
    }
  
    const showDrawer = () => {
      setOpenFilter(!openFilter);
    };
  
    const setFilterApplied = () => {
      setIsFilterApplied(!isFilterApplied)
    }
  
    const removeFilter = (field: string) => {
        form.setFieldsValue({[field]: undefined})
        form.submit()
    }
  
    const handleSearch = (
        confirm: (param?: FilterConfirmProps) => void
      ) => {
        confirm();
      };
  
    return (
      <>
        <Row gutter={[16, 16]} style={{ marginBottom: 14 }}>
          <Col xl={24} xs={24}>
          <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Space direction="horizontal" style={{ flexWrap: 'wrap' }}>
              <Button
                icon={<Avatar size={16} shape="square" src="/images/filter.png" />}
                style={{ border: '1px black', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)', backgroundColor: "#E8E8E8" }}
                onClick={() => showDrawer()}
              >
                {t("notices.filter.button")}
              </Button>
              {
                Object.keys(form.getFieldsValue()).filter(f => form.getFieldValue(f) != null).map((f: string, idx: number) => (           

                  <Tag closable color={['magenta', 'gold', 'cyan', 'purple'][idx]} onClose={() => removeFilter(f)} style={{ height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {remappedTagsFilter(f)}
                  </Tag>
                 
                ))             
              }
            </Space>
            <Button
                icon={<Icons.PlusCircleTwoTone />}
                style={{ width: "100px" }}
                onClick={() => createShow()}
              >
            </Button>
          </Row>
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ paddingTop: "15px" }}>
          <Col xl={24} xs={24}>
          <Table {...tableProps} locale={{
            emptyText: () => (<div>
              <Space direction="vertical" align="center">
                <Avatar size={64} style={{ opacity: "0.4"}} src="/images/dart.png"></Avatar>
                <Typography.Text strong style={{ opacity: "0.4" }}>Dati assenti</Typography.Text>
              </Space>
            </div>)
          }} rowKey="id">
              <Table.Column
                  
                  key="type"
                  dataIndex="type"
                  title={t("news.fields.type.label")}
                  render={value =>
                    <Avatar
                      size={{
                        md: 24,
                        lg: 24,
                        xl: 24,
                        xxl: 24,
                      }}
                      shape="square"
                      src={`/images/${value?.toLowerCase()}.png`}
                    />}
                />
              <Table.Column
                width={"30%"}
                key="title"
                dataIndex="title"
                title={t("news.fields.title")}
                
                render={(value: string, record: IEvent) => {
                  return (
                    <Space direction="horizontal"  style={{ width: "100%" }}>
                      <Typography.Text strong>{record.title}</Typography.Text>
                      <Typography.Text style={{ lineBreak: 'strict', wordBreak: 'break-word' }}>{record.body}</Typography.Text>
                    </Space>
                  )
                }}
                filterDropdown={({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }: any) => ((
                    <div style={{ padding: 8 }} onKeyDown={e => e.stopPropagation()}>
                      <Input
                        placeholder={`Ricerca per denominazione`}
                        value={`${selectedKeys[0] || ''}`}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(confirm)}
                        style={{ marginBottom: 8, display: 'block' }}
                      />
                    </div>
                  ))}
                  filterIcon={(filtered: boolean) => (
                    <Icons.SearchOutlined style={{ color: filtered ? '#1890ff' : undefined  }} />
                  )}
              />
              <Table.Column
                key="rangeCreatedAt"
                dataIndex="isActiveFromDate"
                title={t("news.fields.isActiveFromToDate")} 
                render={(value: string, record: IEvent) => {
                  return (<>
                    {
                      record?.isActiveToDate && record.isActiveFromDate ? (
                        <>
                          <DateField value={value} format="DD/MM/YYYY" /> - <DateField value={record?.isActiveToDate} format="DD/MM/YYYY" />
                        </>
                      ) : (
                        record?.isActiveFromDate && !record.isActiveToDate ? (
                          <>
                            <Space direction="horizontal">
                              <Typography.Text>Dal </Typography.Text>
                              <DateField value={value} format="DD/MM/YYYY" />
                            </Space>
                          </>
                        ) : (
                          record?.isActiveToDate ? (
                            <Space direction="horizontal">
                              <Typography.Text>Al </Typography.Text>
                              <DateField value={record?.isActiveToDate} format="DD/MM/YYYY" />
                            </Space>
                          ) : ("Sempre"))
                      )
                    }
  
                  </>)
                }}
                filterDropdown={({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }: any) => ((
                    <div style={{ width: "400px", padding: 8, display: 'flex', alignItems: 'center', justifyContent: 'center' }} onKeyDown={e => e.stopPropagation()}>
                      <DatePicker.RangePicker onChange={e => setSelectedKeys(e ? e[0]?.toISOString().split('T')[0] + '/' + e[1]?.toISOString().split('T')[0] : null)} locale={require("dayjs/locale/it")}/>
                      <Button style={{ backgroundColor: "#FFA500", border: 'none', marginLeft: "5px" }} onClick={() => handleSearch(confirm)}>Filtra</Button>
                    </div>
                  ))}
                  filterIcon={(filtered: boolean) => (
                    <Icons.ClockCircleFilled style={{ color: filtered ? '#1890ff' : undefined  }} />
                  )}
              />
              <Table.Column
                key="isActive"
                dataIndex="isActive"
                title={t("news.fields.isActive.label")}
                
                render={(value) =>
                  <Tag color={activeStatusColor(value)?.color}>{value === true ? t("news.fields.isActive.true") : t("news.fields.isActive.false")}</Tag>
                }
                filters={[
                    {
                      text: t("users.filter.isActive.true"),
                      value: "true",
                    },
                    {
                      text: t("users.filter.isActive.false"),
                      value: "false",
                    }
                  ]}
                filterIcon={(filtered: boolean) => (
                    <Icons.FilterTwoTone style={{ color: filtered ? '#1890ff' : undefined  }} />
                  )}
              />
              <Table.Column<IEvent>
                title={""}
                dataIndex="actions"
                fixed="right"
                render={(_, record: IEvent) => (
                  <Space>
                    <EditButton
                      className="zoom"
                      icon={<Avatar size={24} shape="square" src="/images/pencil.png" />}
                      style={{ border: "none", marginBottom: "5px", marginLeft: "5px" }}
                      hideText
                      size="small"
                      recordItemId={record.id}
                      onClick={() => editShow(record.id)}
                    />
                    <DeleteButton
                      className="zoom"
                      icon={<Avatar size={24} shape="square" src="/images/card-delete.png" />}
                      style={{ border: "none", marginBottom: "5px", marginLeft: "5px" }}
                      size="small"
                      hideText
                      recordItemId={record.id}
                    />
                  </Space>
                )}
              />
            </Table>
          </Col>
        </Row>
        <EditNews
          drawerProps={editDrawerProps}
          formProps={editFormProps}
          saveButtonProps={editSaveButtonProps}
        />
        <CreateNews
          drawerProps={createDrawerProps}
          formProps={createFormProps}
          saveButtonProps={createSaveButtonProps}
        />
        <Drawer
          title="Filtri"
          placement={'right'}
          closable={false}
          onClose={() => {
            if(Object.values(form.getFieldsValue()).every(el => el === undefined)) {
              form.resetFields()
            }
            showDrawer()
          }}
          visible={openFilter}
          key={'right'}
        >
          <Form layout="vertical" {...searchFormProps} form={form}>
            <Row gutter={16}>
              <Col xs={24} xl={24} md={24}>
                <Form.Item label={t("news.filter.type.label")} name="type">
                  <Select
                    allowClear
                    style={{ minWidth: 150 }}
                    placeholder={t("news.filter.type.placeholder")}
                    options={["WARNING", "EVENT", "INFO", "NEWS", "ALARM"].map(_ => ({
                      label: _,
                      value: _
                    }))}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} xl={24} md={24}>
                <Form.Item label={t("news.filter.isActive.label")} name="isActive">
                  <Select
                    allowClear
                    placeholder={t("news.filter.isActive.placeholder")}
                    options={[
                      {
                        label: t("news.filter.isActive.true"),
                        value: "true",
                      },
                      {
                        label: t("news.filter.isActive.false"),
                        value: "false",
                      }
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} xl={24} md={24}>
                <Form.Item label={t("news.filter.createdAt.label")} name="rangeCreatedAt">
                  <RangePicker style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col xs={24} xl={24} md={24} style={{ position: 'absolute', bottom: 0, left: 20, width: "90%" }}>
                <Form.Item label=" ">
                  <Button style={{ width: "100%", backgroundColor: "#3f3c7e" }} htmlType="submit" type="primary" onClick={() => {
                    setFilterApplied()
                    showDrawer()
                  }}>
                    {t("news.filter.submit")}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Drawer>
      </>
    );
  };
  