import React from "react";
import { Layout as AntdLayout, Grid } from "antd";

import { RefineLayoutLayoutProps } from "./types";

export const Layout: React.FC<RefineLayoutLayoutProps> = ({
    children,
    Header,
    Sider,
    Footer,
    OffLayoutArea,
}) => {
    const breakpoint = Grid.useBreakpoint();

    const SiderToRender = Sider;
    const HeaderToRender = Header;
    const FooterToRender = Footer

    const isSmall = typeof breakpoint.sm === "undefined" ? true : breakpoint.sm;
    return (
        <AntdLayout style={{ minHeight: "100vh" }}>
            {SiderToRender && <SiderToRender /> }
            <AntdLayout>
                {HeaderToRender && <HeaderToRender /> }
                <AntdLayout.Content>
                    <div
                        style={{
                            padding: isSmall ? 24 : 12,
                            minHeight: 360,
                        }}
                    >
                        {children}
                    </div>
                    {OffLayoutArea && <OffLayoutArea />}
                </AntdLayout.Content>
                {FooterToRender && <FooterToRender />}
            </AntdLayout>
        </AntdLayout>
    );
};
