import { useTranslate } from "@pankod/refine-core";

import {
    Edit,
    Drawer,
    DrawerProps,
    Form,
    FormProps,
    Input,
    Radio,
    Select,
    ButtonProps,
    Grid,
    Avatar
} from "@pankod/refine-antd";
import { DatePicker } from 'antd';
import moment from 'moment';
import { useEffect } from "react";
import { IMember } from "interfaces";

type EditTaskProps = {
    drawerProps: DrawerProps;
    formProps: FormProps;
    saveButtonProps: ButtonProps;
    users: Array<IMember>
};

export const EditTask: React.FC<EditTaskProps> = ({
    drawerProps,
    formProps,
    saveButtonProps,
    users
}) => {
    const t = useTranslate();
    const breakpoint = Grid.useBreakpoint();
    const [form] = Form.useForm();

    useEffect(() => {
        if (formProps.initialValues) {
            form.setFieldsValue({ 
                ...formProps.initialValues,
                sentByAppUserAt: formProps.initialValues.sentByAppUserAt._id
            });
        }
    }, [formProps.initialValues])

    return (
        <Drawer
            {...drawerProps}
            width={breakpoint.sm ? "500px" : "100%"}
            bodyStyle={{ padding: 0 }}
            zIndex={1001}
        >
            <Edit
                saveButtonProps={{
                    ...saveButtonProps,
                    onClick: () => form.submit(),
                   style: { backgroundColor: "#3f3c7e", border: 'none' }
                }}
                pageHeaderProps={{ extra: null }}
                resource="tasks"
            >
               <Form layout="vertical" {...formProps} form={form}>
                <Form.Item
                        label={t("tasks.fields.title")}
                        name="title"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={t("news.fields.type.label")}
                        name="type"
                        rules={[
                        {
                            required: true,
                        },
                        ]}
                    >
                        <Select
                        options={[
                            {
                                label: 'PRIORITA\' 1',
                                value: t("tasks.fields.type.priority1"),
                            },
                            {
                                label: 'PRIORITA\' 2',
                                value: t("tasks.fields.type.priority2"),
                            },
                            {
                                label: 'PRIORITA\' 3',
                                value: t("tasks.fields.type.priority3"),
                            },
                            {
                                label: 'PRIORITA\' 4',
                                value: t("tasks.fields.type.priority4"),
                            }
                        ]}
                        />
                    </Form.Item>
                    <Form.Item
                        label={t("tasks.fields.body")}
                        name="body"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input.TextArea style={{ height: "100px" }} />
                    </Form.Item>
                    <Form.Item
                        label={t("tasks.fields.summary")}
                        name="summary"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input.TextArea style={{ height: "100px" }} />
                    </Form.Item>
                    <Form.Item
                        label={t("tasks.fields.operators")}
                        name='sentByAppUserAt'
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Select
                            options={users?.map((_: IMember) => ({
                                label: (
                                    <span style={{ display: "inline-flex" }}>
                                        {_.displayName}
                                        <Avatar
                                            size={{
                                                md: 20,
                                                lg: 20,
                                                xl: 20,
                                                xxl: 20,
                                            }}
                                            src={_?.avatarUrl[0]?.url || "/images/profile.png"}
                                        />
                                    </span>
                                ),
                                value: _.id
                            })) ?? []}
                        />
                    </Form.Item>
                    <Form.Item
                        label={t("tasks.fields.isActive.label")}
                        name="isActive"
                        rules={[{ required: true }]}
                        initialValue={false}
                    >
                        <Radio.Group>
                            <Radio value={true}>{t("status.enable")}</Radio>
                            <Radio value={false}>{t("status.disable")}</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        label={t("tasks.fields.isActiveFromDate")}
                        name="isActiveFromDate"
                        rules={[
                            {
                                required: false,
                                async validator(rule, value) {
                                    if (!value) return Promise.resolve()
                                    const isActiveToDate = form.getFieldValue("isActiveToDate")
                                    if (!isActiveToDate) return Promise.resolve()
                                    if (new Date(value).getTime() > new Date(isActiveToDate).getTime()) return Promise.reject("Non può essere maggiore della data a")
                                    return Promise.resolve()
                                },
                            },
                        ]}
                        getValueProps={(i) => ({value: i ? moment(i) : null})}
                    >
                         <DatePicker format= 'DD/MM/YYYY' style={{ width: "60%" }} locale={require("dayjs/locale/it")}/>
                    </Form.Item>
                    <Form.Item
                        label={t("tasks.fields.isActiveToDate")}
                        name="isActiveToDate"
                        rules={[
                            {
                                required: false,
                                async validator(rule, value) {
                                    if (!value) return Promise.resolve()
                                    const isActiveFromDate = form.getFieldValue("isActiveFromDate")
                                    if (!isActiveFromDate) return Promise.resolve()
                                    if (new Date(value).getTime() <= new Date(isActiveFromDate).getTime()) return Promise.reject("Non può esssre uguale o inferiore alla data da")
                                    return Promise.resolve()
                                },
                            },
                        ]}
                        getValueProps={(i) => ({value: i ? moment(i) : null})}
                    >
                         <DatePicker format= 'DD/MM/YYYY' style={{ width: "60%" }} locale={require("dayjs/locale/it")}/>
                    </Form.Item>
                </Form>
            </Edit>
        </Drawer>
    );
};