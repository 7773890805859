import {
  useTranslate,
  IResourceComponentsProps,
  CrudFilters,
  HttpError,
  useList,
  useExport
} from "@pankod/refine-core";

import {
  Table,
  useTable,
  DatePicker,
  Row,
  Col,
  Tag,
  Button,
  EditButton,
  DeleteButton,
  Space,
  useDrawerForm,
  Avatar,
  DateField,
  Typography,
  Tooltip,
  ExportButton,
  Input,
  Icons
} from "@pankod/refine-antd";
import { ITask, ITaskFilterVariables, IMember } from "interfaces";
import { activeStatusColor } from "utils";
import "./style.less";

import type { FilterConfirmProps } from 'antd/es/table/interface';
import { CreateTask, EditTask } from "components/tasks";

export const TasksList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, sorter, filters  } = useTable<
    ITask,
    HttpError,
    ITaskFilterVariables
  >({
    initialSorter: [
      {
        field: "createdAt",
        order: "desc",
      },
    ],
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { q, isActive, createdAt, type, sentByAppUserAt } = params;

      filters.push({
        field: "q",
        operator: "eq",
        value: q,
      });

      filters.push(
        {
          field: "createdAt",
          operator: "gte",
          value: createdAt
            ? createdAt[0].startOf("day").toISOString()
            : undefined,
        },
        {
          field: "createdAt",
          operator: "lte",
          value: createdAt
            ? createdAt[1].endOf("day").toISOString()
            : undefined,
        }
      );

      filters.push({
        field: "isActive",
        operator: "eq",
        value: isActive,
      });

      filters.push({
        field: "type",
        operator: "eq",
        value: type,
      });

      filters.push({
        field: "sentByAppUserAt",
        operator: "eq",
        value: sentByAppUserAt,
      });

      return filters;
    },
    syncWithLocation: false,
  });

  const { data } = useList<IMember>({
    resource: "users",
    config: {
      filters: [{
        field: "isAppLoginActive",
        operator: "eq",
        value: true
      }]
    }
  });

  const {
    drawerProps: editDrawerProps,
    formProps: editFormProps,
    saveButtonProps: editSaveButtonProps,
    show: editShow
  } = useDrawerForm<ITask>({
    action: "edit",
    resource: "tasks",
    redirect: false,
  });

  const {
    drawerProps: createDrawerProps,
    formProps: createFormProps,
    saveButtonProps: createSaveButtonProps,
    show: createShow
  } = useDrawerForm<ITask>({
    action: "create",
    resource: "tasks",
    redirect: false
  });
  const t = useTranslate();

  const { isLoading, triggerExport } = useExport<ITask>({
    sorter,
    filters,
    pageSize: 50,
    maxItemCount: 50,
    exportOptions: {
      filename: `Task-${new Date().toISOString().substring(0,10)}`
    },
    mapData: (item) => {
      return {
        id: item.id,
        Title: item.title,
        Body: item.body,
        Summary: item.summary,
        Assegnato: item.sentByAppUserAt?._id,
        AttivoDa: item.isActiveFromDate || '-',
        AttivoAl: item.isActiveToDate || '-',
      };
    },
  });

  const Export: React.FC = () => (
    <ExportButton children="Esporta" style={{ order: '1px black', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)' }} onClick={triggerExport} loading={isLoading} />
  );

  const handleSearch = (
    confirm: (param?: FilterConfirmProps) => void
  ) => {
    confirm();
  };
  
  const mappedPriorityImage = (text: string) => {
    let fileName = 'red'

    switch(text) {
      case 'priorita2':
          fileName = 'yellow'
          break
      case 'priorita3':
          fileName = 'orange'
          break
      case 'priorita1':
          fileName = 'green'
    }

    return fileName
  }


  return (
    <>
      <Row gutter={[16, 16]} style={{ marginBottom: 14 }}>
        <Col xl={24} xs={24}>
          <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Space direction="horizontal" style={{ flexWrap: 'wrap' }}>
              {
                tableProps?.dataSource && tableProps.dataSource.length > 0 && (
                  <Export />
                )
              }
            </Space>
            <Space direction="horizontal">
              <Button
                icon={<Icons.PlusCircleTwoTone />}
                style={{ width: "100px" }}
                onClick={() => createShow()}
              >
              </Button>
            </Space>
          </Row>
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ paddingTop: "15px" }}>
        <Col xl={24} xs={24}>
          <Table {...tableProps} locale={{
            emptyText: () => (<div>
              <Space direction="vertical" align="center">
                <Avatar size={64} style={{ opacity: "0.4"}} src="/images/dart.png"></Avatar>
                <Typography.Text strong style={{ opacity: "0.4" }}>Dati assenti</Typography.Text>
              </Space>
            </div>)
          }} rowKey="id">
            <Table.Column
                align="center"
                key="type"
                dataIndex="type"
                title={t("news.fields.type.label")}
                render={value => (
                  <Tooltip title={value}>
                  <Avatar
                    size={{
                      md: 10,
                      lg: 14,
                      xl: 24,
                      xxl: 34,
                    }}
                    shape="square"
                    src={`/images/${mappedPriorityImage(value?.toLowerCase())}.png`}
                  />
                  </Tooltip>
                )}
              />
            <Table.Column
              width={"30%"}
              key="q"
              dataIndex="title"
              title={t("tasks.fields.title")}
              align="center"
              render={(value: string, record: ITask) => {
                return (
                  <Space direction="horizontal" align="center" style={{ width: "100%" }}>
                    <Typography.Text strong>{record.title}</Typography.Text>
                    <Typography.Text style={{ lineBreak: 'strict', wordBreak: 'break-word' }}>{record.body}</Typography.Text>
                  </Space>
                )
              }}
              filterDropdown={({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }: any) => ((
                <div style={{ padding: 8 }} onKeyDown={e => e.stopPropagation()}>
                  <Input
                    placeholder={`Descrizione`}
                    value={`${selectedKeys[0] || ''}`}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(confirm)}
                    style={{ marginBottom: 8, display: 'block' }}
                  />
                </div>
              ))}
              filterIcon={(filtered: boolean) => (
                <Icons.SearchOutlined style={{ color: filtered ? '#1890ff' : undefined  }} />
              )}
            />
            <Table.Column
              key="sentByAppUserAt"
              dataIndex="sentByAppUserAt"
              title={t("tasks.fields.sentByAppUserAt")}
              align="center"
              render={(value: IMember, record: ITask) => (
                  <Typography.Text>{value?.displayName}</Typography.Text>
              )}
              filterDropdown={({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }: any) => ((
                <div style={{ padding: 8 }} onKeyDown={e => e.stopPropagation()}>
                  <Input
                    placeholder={`Ricerca per assegnatario`}
                    value={`${selectedKeys[0] || ''}`}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(confirm)}
                    style={{ marginBottom: 8, display: 'block' }}
                  />
                </div>
              ))}
              filterIcon={(filtered: boolean) => (
                <Icons.SearchOutlined style={{ color: filtered ? '#1890ff' : undefined  }} />
              )}
            />
            <Table.Column
              key="type"
              dataIndex="readByAppUserAt"
              title={t("tasks.fields.readByAppUserAt")}
              align="center"
              render={value =>
                <Tooltip placement="top" overlay={value ? "Presa in carico" : "In attesa"}>
                  <Avatar
                      size={{
                        md: 24,
                        lg: 24,
                        xl: 24,
                        xxl: 24,
                      }}
                      shape="square"
                      src={value ? `/images/delivered.png` : '/images/not-delivered.png'}
                    />
                </Tooltip>
              }
            />
            <Table.Column
              key="rangeCreatedAt"
              dataIndex="isActiveFromDate"
              title={t("tasks.fields.isActiveFromToDate")}
              align="center"
              render={(value: string, record: ITask) => {
              
                return (<>
                  {
                    record?.isActiveToDate && record.isActiveFromDate ? (
                      <>
                        <DateField value={value} format="DD/MM/YYYY" /> - <DateField value={record?.isActiveToDate} format="DD/MM/YYYY" />
                      </>
                    ) : (
                      record?.isActiveFromDate && !record.isActiveToDate ? (
                        <>
                          <Space direction="horizontal">
                            <Typography.Text>Dal </Typography.Text>
                            <DateField value={value} format="DD/MM/YYYY" />
                          </Space>
                        </>
                      ) : (
                        record?.isActiveToDate ? (
                          <Space direction="horizontal">
                            <Typography.Text>Al </Typography.Text>
                            <DateField value={record?.isActiveToDate} format="DD/MM/YYYY" />
                          </Space>
                        ) : ("Libero"))
                    )
                  }

                </>)
              }}
              filterDropdown={({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }: any) => ((
                <div style={{ width: "400px", padding: 8, display: 'flex', alignItems: 'center', justifyContent: 'center' }} onKeyDown={e => e.stopPropagation()}>
                  <DatePicker.RangePicker onChange={e => setSelectedKeys(e ? e[0]?.toISOString().split('T')[0] + '/' + e[1]?.toISOString().split('T')[0] : null)} locale={require("dayjs/locale/it")}/>
                  <Button style={{ backgroundColor: "#FFA500", border: 'none', marginLeft: "5px" }} onClick={() => handleSearch(confirm)}>Filtra</Button>
                </div>
              ))}
              filterIcon={(filtered: boolean) => (
                <Icons.ClockCircleFilled style={{ color: filtered ? '#1890ff' : undefined  }} />
              )}
            />
            <Table.Column
              key="isActive"
              dataIndex="isActive"
              title={t("tasks.fields.isActive.label")}
              align="center"
              render={(value) =>
                <Tag color={activeStatusColor(value)?.color}>{value === true ? t("tasks.fields.isActive.true") : t("tasks.fields.isActive.false")}</Tag>
              }
              filters={[
                {
                  text: t("users.filter.isActive.true"),
                  value: "true",
                },
                {
                  text: t("users.filter.isActive.false"),
                  value: "false",
                }
              ]}
              filterIcon={(filtered: boolean) => (
                <Icons.FilterTwoTone style={{ color: filtered ? '#1890ff' : undefined  }} />
              )}
            />
            <Table.Column<ITask>
              title={""}
              dataIndex="actions"
              fixed="right"
              render={(_, record: ITask) => (
                <Space>
                  {
                    !record?.readByAppUserAt && (
                      <EditButton
                        className="zoom"
                        icon={<Avatar size={24} shape="square" src="/images/pencil.png" />}
                        style={{ border: "none", marginBottom: "5px", marginLeft: "5px" }}
                        hideText
                        size="small"
                        recordItemId={record.id}
                        onClick={() => editShow(record.id)}
                      />
                    )
                  }
                  <DeleteButton
                    className="zoom"
                    icon={<Avatar size={24} shape="square" src="/images/card-delete.png" />}
                    style={{ border: "none", marginBottom: "5px", marginLeft: "5px" }}
                    size="small"
                    hideText
                    recordItemId={record.id}
                  />
                </Space>
              )}
            />
          </Table>
        </Col>
      </Row>
      <CreateTask
        drawerProps={createDrawerProps}
        formProps={createFormProps}
        saveButtonProps={createSaveButtonProps}
        users={data?.data || []}
      />
      <EditTask
        drawerProps={editDrawerProps}
        formProps={editFormProps}
        saveButtonProps={editSaveButtonProps}
        users={data?.data || []}
      />
    </>
  );
};