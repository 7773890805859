import { useState, useEffect } from "react";
import {
  useGetIdentity
} from "@pankod/refine-core";

import {
  AntdLayout,
  Avatar,
  Typography,
  Space,
  Grid,
  useDrawerForm,
  Button,
} from "@pankod/refine-antd";

const { Text } = Typography;
const { useBreakpoint } = Grid;

import { IMember } from "../../interfaces";
import "./style.less";
import { EditHeaderUser } from "./edit";
import { HeaderMenu } from "components/menu";

interface IOptionGroup {
  value: string;
  label: string | React.ReactNode;
}

interface IOptions {
  label: string | React.ReactNode;
  options: IOptionGroup[];
}

export const Header: React.FC = () => {
  const { data: user } = useGetIdentity();
  const screens = useBreakpoint();

  const [value, setValue] = useState<string>("");
  const [options, setOptions] = useState<IOptions[]>([]);

  const {
    drawerProps: editDrawerProps,
    formProps: editFormProps,
    saveButtonProps: editSaveButtonProps,
    show: editShow,
  } = useDrawerForm<IMember>({
    action: "edit",
    resource: "users",
    redirect: false,
  });

  useEffect(() => {
    setOptions([]);
  }, [value]);

  return (
    <>
      <AntdLayout.Header
        style={{
          padding: "0px 24px",
          height: "64px",
          backgroundColor: "#3f3c7e",
          border: '1px black', 
          boxShadow: '0 0.1px 10px rgba(0, 0, 0, 0.5)',         
          position: "sticky", 
          top: "0px",
          zIndex: 2
        }}
      > 
        <Space direction="horizontal" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <HeaderMenu />
          <Space>
            <Text ellipsis strong style={{ color: "white" }}>
              {user?.displayName}
            </Text>
            <Button
              style={{
                border: "none",
                backgroundColor: 'transparent'
              }}
              onClick={() => editShow(user.id)}
            >
              <Avatar
                size="large"
                shape="square"
                src={
                  (user?.avatarUrl?.length > 0 &&
                    user.avatarUrl[0]?.url) ||
                  "/images/man.png"
                }
                alt={user?.displayName}
              />
            </Button>
          </Space>
        </Space>
      </AntdLayout.Header>
      <EditHeaderUser
        drawerProps={editDrawerProps}
        formProps={editFormProps}
        saveButtonProps={editSaveButtonProps}
      />
    </>
  );
};
