import {
  useTranslate,
  IResourceComponentsProps,
  CrudFilters,
  HttpError,
  useList,
} from "@pankod/refine-core";

import {
  Table,
  useTable,
  Card,
  Form,
  DatePicker,
  Select,
  FormProps,
  Row,
  Col,
  Tag,
  Button,
  EditButton,
  DeleteButton,
  Space,
  useDrawerForm,
  Avatar,
  DateField,
  Typography,
  Tooltip,
  Icons,

} from "@pankod/refine-antd";
import { INotice, INoticeFilterVariables, IMember } from "interfaces";
import { activeStatusColor } from "utils";
import "./style.less";
import { CreateMessage, EditMessage } from "components/notices"
import { useState } from "react";
import { Drawer } from "antd"
export const NoticesList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, searchFormProps } = useTable<
    INotice,
    HttpError,
    INoticeFilterVariables
  >({
    initialSorter: [
      {
        field: "createdAt",
        order: "desc",
      },
    ],
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { q, isActive, createdAt, type, sentByAppUserAt, rangeCreatedAt } = params;

      filters.push({
        field: "q",
        operator: "eq",
        value: q,
      });

      filters.push(
        {
          field: "createdAt",
          operator: "gte",
          value: createdAt
            ? createdAt[0].startOf("day").toISOString()
            : undefined,
        },
        {
          field: "createdAt",
          operator: "lte",
          value: createdAt
            ? createdAt[1].endOf("day").toISOString()
            : undefined,
        }
      );

      filters.push({
        field: "isActive",
        operator: "eq",
        value: isActive,
      });

      filters.push({
        field: "type",
        operator: "eq",
        value: type,
      });

      filters.push({
        field: "sentByAppUserAt",
        operator: "eq",
        value: sentByAppUserAt,
      });

      filters.push({
        field: "rangeCreatedAt",
        operator: "eq",
        value: rangeCreatedAt,
      });
      
      return filters;
    },
    syncWithLocation: false,
  });

  const { data } = useList<IMember>({
    resource: "users",
    config: {
      filters: [{
        field: "isAppLoginActive",
        operator: "eq",
        value: true
      }]
    }
  });

  const {
    drawerProps: editDrawerProps,
    formProps: editFormProps,
    saveButtonProps: editSaveButtonProps,
    show: editShow
  } = useDrawerForm<INotice>({
    action: "edit",
    resource: "notices",
    redirect: false,
  });

  const {
    drawerProps: createDrawerProps,
    formProps: createFormProps,
    saveButtonProps: createSaveButtonProps,
    show: createShow
  } = useDrawerForm<INotice>({
    action: "create",
    resource: "notices",
    redirect: false
  });
  const { RangePicker } = DatePicker;
  const t = useTranslate();
  const [openFilter, setOpenFilter] = useState(false);
  const [form] = Form.useForm();
  const [isFilterApplied, setIsFilterApplied] = useState(false)

  const showDrawer = () => {
    setOpenFilter(!openFilter);
  };

  const setFilterApplied = () => {
    setIsFilterApplied(!isFilterApplied)
  }

  const removeFilter = (field: string) => {
      form.setFieldsValue({[field]: undefined})
      form.submit()
  }

  const remappedTagsFilter = (field: string) => {
    if (field === 'type') return form.getFieldValue(field)
    if (field === 'isActive') {
      if (form.getFieldValue(field) === "true") return t("notices.filter.isActive.true")
      return t("notices.filter.isActive.false")
    } else if(field === 'sentByAppUserAt') return data?.data.find(u => u.id === form.getFieldValue(field))?.displayName

    return form.getFieldValue(field)[0]?.$d?.toISOString().split('T')[0] + '/' + form.getFieldValue(field)[1]?.$d?.toISOString().split('T')[0]
  }

  return (
    <>
      <Row gutter={[16, 16]} style={{ marginBottom: 14 }}>
        <Col xl={24} xs={24}>
          <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Space direction="horizontal" style={{ flexWrap: 'wrap' }}>
              <Button
                icon={<Avatar size={16} shape="square" src="/images/filter.png" />}
                style={{ border: '1px black', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)', backgroundColor: "#E8E8E8" }}
                onClick={() => showDrawer()}
              >
                {t("notices.filter.button")}
              </Button>
              {
                Object.keys(form.getFieldsValue()).filter(f => form.getFieldValue(f) != null).map((f: string, idx: number) => (           

                  <Tag closable color={['magenta', 'gold', 'cyan', 'purple'][idx]} onClose={() => removeFilter(f)} style={{ height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {remappedTagsFilter(f)}
                  </Tag>
                 
                ))             
              }
            </Space>
            <Button
                icon={<Icons.PlusCircleTwoTone />}
                style={{ width: "100px" }}
                onClick={() => createShow()}
              >
            </Button>
          </Row>
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ paddingTop: "15px" }}>
        <Col xl={24} xs={24}>
        <Table {...tableProps} locale={{
            emptyText: () => (<div>
              <Space direction="vertical" align="center">
                <Avatar size={64} style={{ opacity: "0.4"}} src="/images/dart.png"></Avatar>
                <Typography.Text strong style={{ opacity: "0.4" }}>Dati assenti</Typography.Text>
              </Space>
            </div>)
          }} rowKey="id">
            <Table.Column
                align="center"
                key="type"
                dataIndex="type"
                title={t("news.fields.type.label")}
                render={value =>
                  <Avatar
                      size={{
                        md: 24,
                        lg: 24,
                        xl: 24,
                        xxl: 24,
                      }}
                    shape="square"
                    src={`/images/${value?.toLowerCase()}.png`}
                  />}
              />
            <Table.Column
              width={"30%"}
              key="title"
              dataIndex="title"
              title={t("news.fields.title")}
              align="center"
              render={(value: string, record: INotice) => {
                return (
                  <Space direction="horizontal" align="center" style={{ width: "100%" }}>
                    <Typography.Text strong>{record.title}</Typography.Text>
                    <Typography.Text style={{ lineBreak: 'strict', wordBreak: 'break-word' }}>{record.body}</Typography.Text>
                  </Space>
                )
              }}
            />
            <Table.Column
              key="lat"
              dataIndex="lat"
              title={t("notices.fields.geo")}
              align="center"
              render={value =>
                <Avatar
                  size={{
                    md: 24,
                    lg: 24,
                    xl: 24,
                    xxl: 24,
                  }}
                  shape="square"
                  src={value ? `/images/geo.png` : '/images/no-geo.png'}
                />
              }
            />
            <Table.Column
              key="sentByAppUserAt"
              dataIndex="sentByAppUserAt"
              title={t("notices.fields.sentByAppUserAt")}
              align="center"
              render={(value: IMember, record: INotice) => (
                  <Typography.Text>{value?.displayName}</Typography.Text>
              )}
            />
            <Table.Column
              key="type"
              dataIndex="readByAppUserAt"
              title={t("news.fields.read")}
              align="center"
              render={value =>
                <Tooltip placement="top" overlay={value ? "Letto" : "Da leggere"}>
                  <Avatar
                      size={{
                        md: 24,
                        lg: 24,
                        xl: 24,
                        xxl: 24,
                      }}
                      shape="square"
                      src={value ? `/images/delivered.png` : '/images/not-delivered.png'}
                    />
                </Tooltip>
              }
            />
            <Table.Column
              key="isActiveFromDate"
              dataIndex="isActiveFromDate"
              title={t("news.fields.isActiveFromToDate")}
              align="center"
              render={(value: string, record: INotice) => {

                return (<>
                  {
                    record?.isActiveToDate && record.isActiveFromDate ? (
                      <>
                        <DateField value={value} format="DD/MM/YYYY" /> - <DateField value={record?.isActiveToDate} format="DD/MM/YYYY" />
                      </>
                    ) : (
                      record?.isActiveFromDate && !record.isActiveToDate ? (
                        <>
                          <Space direction="horizontal">
                            <Typography.Text>Dal </Typography.Text>
                            <DateField value={value} format="DD/MM/YYYY" />
                          </Space>
                        </>
                      ) : (
                        record?.isActiveToDate ? (
                          <Space direction="horizontal">
                            <Typography.Text>Al </Typography.Text>
                            <DateField value={record?.isActiveToDate} format="DD/MM/YYYY" />
                          </Space>
                        ) : ("Sempre"))
                    )
                  }

                </>)
              }}
            />
            <Table.Column
              key="isActive"
              dataIndex="isActive"
              title={t("notices.fields.isActive.label")}
              align="center"
              render={(value) =>
                <Tag color={activeStatusColor(value)?.color}>{value === true ? t("notices.fields.isActive.true") : t("notices.fields.isActive.false")}</Tag>
              }
            />
            <Table.Column<INotice>
              title={""}
              dataIndex="actions"
              fixed="right"
              render={(_, record: INotice) => (
                <Space>
                  {
                    !record?.readByAppUserAt && (
                      <EditButton
                        className="zoom"
                        icon={<Avatar size={24} shape="square" src="/images/pencil.png" />}
                        style={{ border: "none", marginBottom: "5px", marginLeft: "5px" }}
                        hideText
                        size="small"
                        recordItemId={record.id}
                        onClick={() => editShow(record.id)}
                      />
                    )
                  }
                  <DeleteButton
                    className="zoom"
                    icon={<Avatar size={24} shape="square" src="/images/card-delete.png" />}
                    style={{ border: "none", marginBottom: "5px", marginLeft: "5px" }}
                    size="small"
                    hideText
                    recordItemId={record.id}
                  />
                </Space>
              )}
            />
          </Table>
        </Col>
      </Row>
      <CreateMessage
        drawerProps={createDrawerProps}
        formProps={createFormProps}
        saveButtonProps={createSaveButtonProps}
        users={data?.data || []}
      />
      <EditMessage
        drawerProps={editDrawerProps}
        formProps={editFormProps}
        saveButtonProps={editSaveButtonProps}
        users={data?.data || []}
      />
      <Drawer
        title="Filtri"
        placement={'right'}
        closable={false}
        onClose={() => {
          if(Object.values(form.getFieldsValue()).every(el => el === undefined)) {
            form.resetFields()
          }
          showDrawer()
        }}
        visible={openFilter}
        key={'right'}
      >
        <Form layout="vertical" {...searchFormProps} form={form}>
          <Row gutter={16}>
            <Col xs={24} xl={24} md={24}>
              <Form.Item label={t("notices.filter.type.label")} name="type">
                <Select
                  allowClear
                  style={{ minWidth: 150 }}
                  placeholder={t("notices.filter.type.placeholder")}
                  options={["WARNING", "EVENT", "INFO", "NEWS", "ALARM"].map(_ => ({
                    label: _,
                    value: _
                  }))}
                />
              </Form.Item>
            </Col>
            <Col xs={24} xl={24} md={24}>
              <Form.Item label={t("notices.filter.isActive.label")} name="isActive">
                <Select
                  allowClear
                  placeholder={t("notices.filter.isActive.placeholder")}
                  options={[
                    {
                      label: t("notices.filter.isActive.true"),
                      value: "true",
                    },
                    {
                      label: t("notices.filter.isActive.false"),
                      value: "false",
                    }
                  ]}
                />
              </Form.Item>
            </Col>
            <Col xs={24} xl={24} md={24}>
              <Form.Item label={t("notices.filter.operators")} name="sentByAppUserAt">
                <Select
                  allowClear
                  style={{ minWidth: 150 }}
                  placeholder={t("notices.filter.placeholderoperator")}
                  options={(data?.data || [])?.map(d => {
                    return {
                      label: d.displayName,
                      value: d.id
                    }
                  })}
                />
              </Form.Item>
            </Col>
            <Col xs={24} xl={24} md={24}>
              <Form.Item label={t("notices.filter.createdAt.label")} name="rangeCreatedAt">
                <RangePicker style={{ width: "100%" }} locale={require("dayjs/locale/it")}/>
              </Form.Item>
            </Col>
            <Col xs={24} xl={24} md={24} style={{ position: 'absolute', bottom: 0, left: 20, width: "90%" }}>
              <Form.Item label=" ">
                <Button style={{ width: "100%", backgroundColor: "#3f3c7e" }} htmlType="submit" type="primary" onClick={() => {
                  setFilterApplied()
                  showDrawer()
                }}>
                  {t("notices.filter.submit")}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
};