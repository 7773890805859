import {
  useTranslate,
  IResourceComponentsProps,
  CrudFilters,
  HttpError,
  useExport,
  useDeleteMany,
  useModal,
} from "@pankod/refine-core";

import {
  List,
  Table,
  useTable,
  DateField,
  Button,
  Row,
  Col,
  Tag,
  Typography,
  Avatar,
  ExportButton,
  Space,
  Modal,
  Input,
  Icons
} from "@pankod/refine-antd";

import type { FilterConfirmProps } from 'antd/es/table/interface';
import { ILog, IMemberLogFilterVariables } from "../interfaces";
import { useRef } from "react";

const { Text } = Typography;

export const UserLogList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, sorter, filters } = useTable<
    ILog,
    HttpError,
    IMemberLogFilterVariables
  >({
    initialSorter: [
      {
        field: "createdAt",
        order: "desc",
      },
    ],
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { createdAt, userId } = params;

      filters.push(
        {
          field: "createdAt",
          operator: "gte",
          value: createdAt
            ? createdAt[0].startOf("day").toISOString()
            : undefined,
        },
        {
          field: "createdAt",
          operator: "lte",
          value: createdAt
            ? createdAt[1].endOf("day").toISOString()
            : undefined,
        }
      );

      filters.push({
        field: "userId",
        operator: "eq",
        value: userId !== "" ? userId : undefined,
      });

      return filters;
    },
    syncWithLocation: false,
  });

  const t = useTranslate();
  const { mutate } = useDeleteMany<ILog>();
  const { visible, show, close } = useModal();

  const operationStatusColor = (
    operation: string,
  ): { color: string; text: string } | undefined => {
    switch (operation) {
      case "CREATED":
        return { color: "orange", text: "created" };
      case "LIST":
        return { color: "cyan", text: "list" };
      case "SHOW":
        return { color: "green", text: "show" };
      case "UPDATE":
        return { color: "pink", text: "update" };
      case "DELETE":
        return { color: "red", text: "delete" };
      case "LOGIN":
        return { color: "geekblue", text: "login" };
      case "UPLOAD_ICON":
        return { color: "volcano", text: "upload_icon" };
      default:
        return { color: "green", text: "" };
    }
  };

  const { isLoading, triggerExport } = useExport<ILog>({
    sorter,
    filters,
    exportOptions: {
      filename: `report-${new Date().toISOString().substring(0,10)}`
    },
    mapData: (item: ILog) => {
      return {
        modelRef: item.onModelRef || '',
        modelType: item.onModelType || '',
        operazione: item.operation || '',
        nome: item.userDisplayName || '',
        userId: item.userId || '',
        data_creazione: new Date(item.createdAt * 1000)
      };
    },
  });

  const Export: React.FC = () => (
    <ExportButton icon={<></>} children="Esporta" style={{ height: "50px", backgroundColor: "#3f3c7e", color: "white", border: '1px black', boxShadow: '0 2px 10px #3f3c7e' }} onClick={triggerExport} loading={isLoading} />
  );

  const eraseLog = () => {
    mutate({
      resource: "logs",
      ids: ['removeAll'],
    })
    close()
  }

  const searchInput = useRef(null);

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: any,
  ) => {
    confirm();
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xl={24} xs={24}>
          <List
            pageHeaderProps={{
              extra: (
                <>
                <Space direction="horizontal" style={{ flexWrap: 'wrap' }}>
                    <Button
                      icon={<Icons.DeleteFilled />}
                      style={{ width: "50px", height: "50px" }}
                      onClick={show}
                    />
                    {tableProps?.dataSource && tableProps.dataSource.length > 0 && <Export />}
                </Space>
                </>            
              ),
            }}
          >
            <Table {...tableProps}  locale={{
            emptyText: () => (
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "98px", backgroundColor: 'white', borderRadius: "30px" }}>
                <Typography.Text strong style={{ opacity: "0.4", fontSize: "20px" }}>Nessun log persente</Typography.Text>
            </div>)
          }} rowKey="id" >
              <Table.Column
                key="userId"
                dataIndex="userId"
                title={t("logs.fields.userId")}
                filterDropdown={({ setSelectedKeys, selectedKeys, confirm }: any) => ((
                  <div style={{ padding: 8 }} onKeyDown={e => e.stopPropagation()}>
                    <Input
                      ref={searchInput}
                      placeholder={`Ricerca per id`}
                      value={`${selectedKeys[0] || ''}`}
                      onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                      onPressEnter={() => handleSearch(selectedKeys as string[], confirm, "userId")}
                      style={{ marginBottom: 8, display: 'block' }}
                    />
                  </div>
                ))}
                render={(value: string, _: ILog) => (
                  <div style={{ display: 'flex', alignItems: 'start'}}>
                    <div className="product-text">
                      <Text style={{ fontSize: 18, fontWeight: 800 }}>
                        {_.userDisplayName}
                      </Text>
                    </div>
                  </div>
                )}
              />
              <Table.Column
                key="operation"
                dataIndex="operation"
                title={t("logs.fields.operation")}
                render={(value: string, _: ILog) => (
                  <>
                    <Row>
                      <Tag color={operationStatusColor(value)?.color}>{value + " " + _.onModelType }</Tag>
                    </Row>
                  </>
                )}
              />
              <Table.Column
                key="operation"
                dataIndex="operation"
                title={t("logs.fields.model")}
                render={(value: string, _: ILog) => (
                  <>
                    {_?.onModelRef ? (<Row>
                      <Tag color={"#3f3c7e"}>{_.onModelRef}</Tag>
                    </Row>) : (null)}
                  </>
                )}
              />
              <Table.Column
                key="createdAt"
                dataIndex="createdAt"
                title={t("logs.fields.createdAt")}
                render={(value) => <DateField value={new Date(0).setUTCSeconds(value)} locales={require("dayjs/locale/it")} format="LLL" />}
                sorter={(a: number, b: number) => b - a}
              />
            </Table>
          </List>
        </Col>
      </Row>
      <Modal
          title={<div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>Cancella</div>}
          centered
          visible={visible}
          onOk={eraseLog}
          onCancel={close}
          okType="danger"
        >
          <Space direction="vertical" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Typography.Text type="danger">ATTENZIONE!</Typography.Text>
            <Typography.Text>Stai per eliminare **DEFINITIVAMENTE** tutti i log registrati. Questa operazione **NON** può essere annullata.</Typography.Text>
            <Typography.Text>Prima di procedere, ti consigliamo vivamente di creare un backup dei tuoi dati cliccando sul pulsante "Esporta".</Typography.Text>
            <Typography.Text>Sei sicuro di voler continuare?</Typography.Text>
          </Space>
        </Modal>
    </>
  );
};