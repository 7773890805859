import { useTranslate } from "@pankod/refine-core";

import {
    Drawer,
    DrawerProps,
    Form,
    FormProps,
    Input,
    Radio,
    ButtonProps,
    Grid,
    Create,
    Typography,
    Select,
    DatePicker,
} from "@pankod/refine-antd";
import { useEffect } from "react";

type CreateEventProps = {
    drawerProps: DrawerProps;
    formProps: FormProps;
    saveButtonProps: ButtonProps;
};

export const CreateNews: React.FC<CreateEventProps> = ({
    drawerProps,
    formProps,
    saveButtonProps
}) => {
    const t = useTranslate();
    const breakpoint = Grid.useBreakpoint();
    const [form] = Form.useForm();

    useEffect(() => {
        if (drawerProps.visible === true) {
            form.resetFields()
        }
    }, [drawerProps.visible])
    
    return (
        <Drawer
            {...drawerProps}
            width={breakpoint.sm ? "500px" : "100%"}
            bodyStyle={{ padding: 0 }}
            zIndex={1001}
        >
            <Create
                saveButtonProps={{
                    ...saveButtonProps,
                    onClick: () =>form.submit(),
                    style: { backgroundColor: "#3f3c7e" }
                }}
                pageHeaderProps={{ extra: null }}
                resource="news"
            >
                <Form {...formProps} layout="vertical" form={form}>
                    <Form.Item
                        label={t("news.fields.title")}
                        name="title"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={t("news.fields.type.label")}
                        name="type"
                        rules={[
                        {
                            required: true,
                        },
                        ]}
                    >
                        <Select
                        options={[
                            {
                                label: t("news.fields.type.event"),
                                value: t("news.fields.type.event"),
                            },
                            {
                                label: t("news.fields.type.info"),
                                value: t("news.fields.type.info"),
                            },
                            {
                                label: t("news.fields.type.warning"),
                                value: t("news.fields.type.warning"),
                            },
                            {
                                label: t("news.fields.type.news"),
                                value: t("news.fields.type.news"),
                            },
                            {
                                label: t("news.fields.type.alarm"),
                                value: t("news.fields.type.alarm"),
                            },
                        ]}
                        />
                    </Form.Item>
                    <Form.Item
                        label={t("news.fields.body")}
                        name="body"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input.TextArea style={{ height: "100px" }} />
                    </Form.Item>
                    <Form.Item
                        label={t("news.fields.summary")}
                        name="summary"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input.TextArea style={{ height: "100px" }} />
                    </Form.Item>
                    <Form.Item
                        label={t("users.fields.isActive.label")}
                        name="isActive"
                        rules={[{ required: true }]}
                        initialValue={false}
                    >
                        <Radio.Group>
                            <Radio value={true}>{t("status.enable")}</Radio>
                            <Radio value={false}>{t("status.disable")}</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        label={t("news.fields.isActiveFromDate")}
                        name="isActiveFromDate"
                        rules={[
                            {
                                required: false,
                                async validator(rule, value) {
                                    if (!value) return Promise.resolve()
                                    const isActiveToDate = form.getFieldValue("isActiveToDate")
                                    if (!isActiveToDate) return Promise.resolve()
                                    if (value.$d.getTime() > isActiveToDate.$d.getTime()) return Promise.reject("Non può maggiore della data a")
                                    return Promise.resolve()
                                },
                            },
                        ]}
                    >
                        <DatePicker format= 'DD/MM/YYYY' style={{ width: "60%" }} locale={require("dayjs/locale/it")}/>
                    </Form.Item>
                    <Form.Item
                        label={t("news.fields.isActiveToDate")}
                        name="isActiveToDate"
                        rules={[
                            {
                                required: false,
                                async validator(rule, value) {
                                    if (!value) return Promise.resolve()
                                    const isActiveFromDate = form.getFieldValue("isActiveFromDate")
                                    if (!isActiveFromDate) return Promise.resolve()
                                    if (value.$d.getTime() <= isActiveFromDate.$d.getTime()) return Promise.reject("Non può esssre uguale o inferiore alla data da")
                                    return Promise.resolve()
                                },
                            },
                        ]}
                    >
                        <DatePicker format= 'DD/MM/YYYY' style={{ width: "60%" }} locale={require("dayjs/locale/it")}/>
                    </Form.Item>
                </Form>
            </Create>
        </Drawer>
    );
};
