import { useTranslate, useApiUrl } from "@pankod/refine-core";

import {
  Drawer,
  DrawerProps,
  Form,
  FormProps,
  Input,
  Radio,
  Select,
  Space,
  ButtonProps,
  Avatar,
  Upload,
  Grid,
  Create,
  Button,
  Icons
} from "@pankod/refine-antd";

import { IMember } from "interfaces";
import { TOKEN_KEY } from "authProvider";
import { useEffect } from "react";

type CreateFileProps = {
  drawerProps: DrawerProps;
  formProps: FormProps;
  saveButtonProps: ButtonProps;
  users: Array<IMember>
};

export const CreateFile: React.FC<CreateFileProps> = ({
  drawerProps,
  formProps,
  saveButtonProps,
  users
}) => {
  const t = useTranslate();
  const apiUrl = useApiUrl();
  const breakpoint = Grid.useBreakpoint();
  const [form] = Form.useForm();
  
  const operators = users || []
  
  const setFileName = (fileName: string) => {
    form.setFieldsValue({
      fileName
    })
  }

  useEffect(() => {
    if (drawerProps.visible === true) {
        form.resetFields()
    }
  }, [drawerProps.visible])
  
  return (
    <Drawer
      {...drawerProps}
      width={breakpoint.sm ? "500px" : "100%"}
      bodyStyle={{ padding: 0 }}
      zIndex={1001}
    >
      <Create
        saveButtonProps={{
          ...saveButtonProps,
          onClick: () =>form.submit(),
         style: { backgroundColor: "#3f3c7e", border: 'none' }
        }}
        pageHeaderProps={{ extra: null }}
        resource="files"
      >
        <Form {...formProps} layout="vertical" form={form}>
          <Form.Item>
            <Form.Item
              name="fileUrl"
              valuePropName="fileUrl"
              getValueFromEvent={(event) => {
                let { fileList } = event;
                const fileUrl = fileList.map((_: any) => ({..._, ..._.response && { url: _.response.url} }))
                if(fileUrl?.length > 0) { setFileName(fileUrl[0].originFileObj?.name as string) }
                return [...fileUrl];
              }}
              noStyle
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Upload.Dragger
                name="file"
                action={`${apiUrl}/files/upload`}
                headers={{
                  "x-token": `${localStorage.getItem(TOKEN_KEY)}`,
                }}
                listType="text"
                maxCount={1}
                style={{ border: "none" }}
              >
                <Space direction="vertical" size={2} style={{ display: "flex", alignItems: 'start' }}>
                  <Button icon={<Icons.UploadOutlined />}>{t("files.fields.upload")}</Button>
                </Space>
              </Upload.Dragger>
            </Form.Item>
          </Form.Item>
          <Form.Item
            label={t("files.fields.fileName")}
            name="fileName"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input disabled/>
          </Form.Item>
          <Form.Item
                label={t("files.fields.users.label")}
                name={'visibleForUserIds'}
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Select
                    mode="multiple"
                    options={operators?.map((_: IMember) => ({
                        label: (
                            <span style={{ display: "inline-flex" }}>
                                {_.displayName}
                                <Avatar
                                    size={{
                                        md: 20,
                                        lg: 20,
                                        xl: 20,
                                        xxl: 20,
                                    }}
                                    src={_?.avatarUrl[0]?.url || "/images/profile.png"}
                                />
                            </span>
                        ),
                        value: _.id
                    })) ?? []}
                />
            </Form.Item>
          <Form.Item
            label={t("users.fields.isActive.label")}
            name="isActive"
            rules={[{ required: true }]}
            initialValue={false}
          >
            <Radio.Group>
              <Radio value={true}>{t("status.enable")}</Radio>
              <Radio value={false}>{t("status.disable")}</Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Create>
    </Drawer>
  );
};
