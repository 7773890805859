import {
  useTranslate,
  IResourceComponentsProps,
  CrudFilters,
  HttpError,
  useList
} from "@pankod/refine-core";

import {
  Table,
  useTable,
  Form,
  DatePicker,
  Row,
  Col,
  Tag,
  Button,
  Input,
  Icons,
  EditButton,
  DeleteButton,
  Space,
  Typography,
  Avatar,
  useDrawerForm,
  DateField,
  Tooltip,
} from "@pankod/refine-antd";
import { IMember, IFile, IFileFilterVariables } from "interfaces";
import { activeStatusColor } from "utils";
import { useState } from "react";

import "./style.less";
import axiosInstance from "httpClient";
import { toastProvider } from "toastProvider";
import { CreateFile, EditFile } from "components/files";
import type { FilterConfirmProps } from 'antd/es/table/interface';

export const FilesList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable<
    IFile,
    HttpError,
    IFileFilterVariables
  >({
    initialSorter: [
      {
        field: "createdAt",
        order: "desc",
      },
    ],
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { q, isActive, createdAt, user, visibleForUserIds } = params;

      filters.push({
        field: "q",
        operator: "eq",
        value: q,
      });

      filters.push(
        {
          field: "createdAt",
          operator: "gte",
          value: createdAt
            ? createdAt[0].startOf("day").toISOString()
            : undefined,
        },
        {
          field: "createdAt",
          operator: "lte",
          value: createdAt
            ? createdAt[1].endOf("day").toISOString()
            : undefined,
        }
      );

      filters.push({
        field: "isActive",
        operator: "eq",
        value: isActive,
      });

      filters.push({
        field: "user",
        operator: "eq",
        value: user,
      });

      filters.push({
        field: "visibleForUserIds",
        operator: "in",
        value: visibleForUserIds
      })

      return filters;
    },
    syncWithLocation: false,
  });

  const downloadDocument = (url: string, fileName: string) => {
    axiosInstance.get(url, {
      responseType: 'blob'
    }).then((response: { data: Blob | MediaSource; }) => {
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', fileName || "file.pdf"); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    }).catch(() => toastProvider.open({ message: "Errore durante il download", type: 'error' }))
  }

  const { data } = useList<IMember>({
    resource: "users"
  });

  const {
    drawerProps: editDrawerProps,
    formProps: editFormProps,
    saveButtonProps: editSaveButtonProps,
    show: editShow
  } = useDrawerForm<IFile>({
    action: "edit",
    resource: "files",
    redirect: false,
  });

  const {
    drawerProps: createDrawerProps,
    formProps: createFormProps,
    saveButtonProps: createSaveButtonProps,
    show: createShow
  } = useDrawerForm<IFile>({
    action: "create",
    resource: "files",
    redirect: false,
  });

  const t = useTranslate();
  const [openFilter, setOpenFilter] = useState(false);
  const [form] = Form.useForm();
  
  const getImageByDocumentType = (fileType: string) => {
    switch (fileType) {
      case "application/pdf":
        return "pdf.png"
      case "text/plain":
        return "txt.png"
      case "text/csv":
        return "csv.png"
      case "application/vnd.ms-powerpoint":
        return "ppt.png"
      case "image/png":
        return "png.png"
      case "application/zip":
        return "zip.png"
      case "application/vnd.rar":
        return "rar.png"
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        return "doc.png"
      case "application/xml":
      case "text/xml":
      case "application/atom+xml":
        return "xml.png"
      case "application/vnd.ms-excel":
        return "xls.png"
    }
    return "file_default.png"
  }

  const handleSearch = (
    confirm: (param?: FilterConfirmProps) => void
  ) => {
    confirm();
  };

  return (
    <>
      <Row gutter={[16, 16]} style={{ marginBottom: 14 }}>
        <Col xl={24} xs={24}>
          <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
            <Space direction="horizontal">
              <Button
                icon={<Icons.PlusCircleTwoTone />}
                style={{ width: "100px" }}
                onClick={() => createShow()}
              >
              </Button>
            </Space>
          </Row>
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ paddingTop: "15px" }}>
        <Col xl={24} xs={24}>
          <Table {...tableProps} locale={{
            emptyText: () => (<div>
              <Space direction="vertical" align="center">
                <Avatar size={64} style={{ opacity: "0.4"}} src="/images/dart.png"></Avatar>
                <Typography.Text strong style={{ opacity: "0.4" }}>Dati assenti</Typography.Text>
              </Space>
            </div>)
          }} rowKey="id">
            <Table.Column
              key="fileUrl"
              dataIndex="fileUrl"
              title={t("files.fields.icon")}
              render={value => (
                <>
                  <Avatar
                    size={{
                      md: 10,
                      lg: 14,
                      xl: 24,
                      xxl: 34,
                    }}
                    shape="square"
                    src={"/images/" + getImageByDocumentType(value ? value[0].type : "")}
                  />
                </>
              )}
            />
            <Table.Column
              key="q"
              dataIndex="fileName"
              title={t("files.fields.fileName")}
              filterDropdown={({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }: any) => ((
                <div style={{ padding: 8 }} onKeyDown={e => e.stopPropagation()}>
                  <Input
                    placeholder={`Ricerca per denominazione`}
                    value={`${selectedKeys[0] || ''}`}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(confirm)}
                    style={{ marginBottom: 8, display: 'block' }}
                  />
                </div>
              ))}
              filterIcon={(filtered: boolean) => (
                <Icons.SearchOutlined style={{ color: filtered ? '#1890ff' : undefined  }} />
              )}
            />
            <Table.Column
              key="visibleForUserIds"
              dataIndex="visibleForUserIds"
              title={t("files.fields.visibleForUserIds")}
              align="center"
              render={(value, _: IFile) =>
                <>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', width: "100%" }}>
                    {
                      _.visibleForUserIds?.length > 0 ?
                        (<div className='container-gowork-operators'>
                          {
                            _.visibleForUserIds?.slice(0, 3).map((operator: IMember, idx: number) => {
                              return (
                                <div className="container-gowork-operators-image" style={{
                                  position: idx === 0 ? 'relative' : 'absolute',
                                  left: idx * 18,
                                  ..._.visibleForUserIds?.length > 2 ? ({
                                    marginRight: '45px'
                                  }) : (
                                    _.visibleForUserIds?.length > 3 ? ({
                                      marginRight: '55px'
                                    }) : (
                                      _.visibleForUserIds?.length > 1 ? ({
                                        marginRight: '18px'
                                      }) : (
                                        _.visibleForUserIds?.length > 3 ? ({
                                          marginRight: '65px'
                                        }) : (
                                          null
                                        )
                                      )
                                    )
                                  )
                                }}>
                                  <Tooltip overlay={operator.displayName}>
                                    <Avatar src={operator.avatarUrl?.length > 0 ? operator.avatarUrl[0].url : "/images/profile.png"} size={32} />
                                  </Tooltip>
                                </div>
                              )
                            })
                          }
                          {
                            _.visibleForUserIds?.length > 3 ? (
                              <>
                                <Tooltip title={""} overlay={
                                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    {_.visibleForUserIds?.slice(3).map((operator: IMember, idx: number) => {
                                      return (
                                        <div style={{ padding: "5px" }}>
                                          <Avatar src={operator.avatarUrl[0]?.url || "/images/profile.png"} size={64} style={{ marginRight: "5px" }} />{operator.displayName}
                                        </div>
                                      )
                                    })
                                    }
                                  </div>}>
                                  <div style={{ position: 'relative', left: 8, backgroundColor: "#BEBEBE", fontSize: "18px", textAlign: 'center', height: "64px", lineHeight: "64px", width: "64px", borderRadius: "45% 45% 45% 45%", color: 'white' }}>
                                    +1
                                  </div>
                                </Tooltip>
                              </>
                            ) : (null)
                          }
                        </div>)
                        :
                        (<Typography.Text style={{ marginTop: "15px", fontSize: "17px" }}>Nessun Operatore associato</Typography.Text>)
                    }

                  </div>
                </>
              }
              filterDropdown={({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }: any) => ((
                <div style={{ padding: 8 }} onKeyDown={e => e.stopPropagation()}>
                  <Input
                    placeholder={`Ricerca per assegnatario`}
                    value={`${selectedKeys[0] || ''}`}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(confirm)}
                    style={{ marginBottom: 8, display: 'block' }}
                  />
                </div>
              ))}
              filterIcon={(filtered: boolean) => (
                <Icons.SearchOutlined style={{ color: filtered ? '#1890ff' : undefined  }} />
              )}
            />
            <Table.Column
              key="rangeCreatedAt"
              dataIndex="createdAt"
              title={t("files.fields.createdAt")}
              render={(value) => <DateField value={new Date(0).setUTCSeconds(value)} locales={require("dayjs/locale/it")} format="LLL" />}
              filterDropdown={({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }: any) => ((
                <div style={{ width: "400px", padding: 8, display: 'flex', alignItems: 'center', justifyContent: 'center' }} onKeyDown={e => e.stopPropagation()}>
                  <DatePicker.RangePicker onChange={e => setSelectedKeys(e ? e[0]?.toISOString().split('T')[0] + '/' + e[1]?.toISOString().split('T')[0] : null)} locale={require("dayjs/locale/it")}/>
                  <Button style={{ backgroundColor: "#FFA500", border: 'none', marginLeft: "5px" }} onClick={() => handleSearch(confirm)}>Filtra</Button>
                </div>
              ))}
              filterIcon={(filtered: boolean) => (
                <Icons.ClockCircleFilled style={{ color: filtered ? '#1890ff' : undefined  }} />
              )}
            />
            <Table.Column
              key="isActive"
              dataIndex="isActive"
              title={t("files.filter.isActive.label")}
              render={(value) =>
                <Tag color={activeStatusColor(value)?.color}>{value === true ? t("users.fields.isActive.true") : t("users.fields.isActive.false")}</Tag>
              }
              filters={[
                {
                  text: t("users.filter.isActive.true"),
                  value: "true",
                },
                {
                  text: t("users.filter.isActive.false"),
                  value: "false",
                }
              ]}
              filterIcon={(filtered: boolean) => (
                <Icons.FilterTwoTone style={{ color: filtered ? '#1890ff' : undefined  }} />
              )}
            />
            <Table.Column<IFile>
              title="Azioni"
              dataIndex="actions"
              fixed="right"
              render={(_, record: IFile) => (
                <Space>
                  {record.fileUrl[0] ? (
                    <Button className="zoom" style={{ border: "none" }} icon={<Avatar src={"/images/download.png"} size={27} />} onClick={() => downloadDocument(record.fileUrl[0].url, record.fileUrl[0].name)} />
                  ) : null}
                  <EditButton
                    className="zoom"
                    icon={<Avatar size={24} shape="square" src="/images/pencil.png" />}
                    style={{ border: "none", marginBottom: "5px", marginLeft: "5px" }}
                    hideText
                    size="small"
                    recordItemId={record.id}
                    onClick={() => editShow(record.id)}
                  />
                  <DeleteButton
                    className="zoom"
                    icon={<Avatar size={24} shape="square" src="/images/card-delete.png" />}
                    style={{ border: "none", marginBottom: "5px", marginLeft: "5px" }}
                    size="small"
                    hideText
                    recordItemId={record.id}
                  />
                </Space>
              )}
            />
          </Table>
        </Col>
      </Row>
      <EditFile
        drawerProps={editDrawerProps}
        formProps={editFormProps}
        saveButtonProps={editSaveButtonProps}
      />
      <CreateFile
        drawerProps={createDrawerProps}
        formProps={createFormProps}
        saveButtonProps={createSaveButtonProps}
        users={data?.data || []}
      />
    </>
  );
};