import {
  useShow,
  IResourceComponentsProps,
  useTranslate,
  HttpError,
} from "@pankod/refine-core";

import {
  Typography,
  Avatar,
  Row,
  Col,
  Card,
  Space,
  Grid,
  DateField,
  Table,
  useTable,
  Tag,
} from "@pankod/refine-antd";
import {
  IMemberActivity,
  IMemberActivityFilterVariables,
  IMember,
  IShift,
  IShiftFilterVariables,
  ILog
} from "../interfaces";
import "./style.less";

const customOldDate = new Date();

customOldDate.setFullYear(1996);

export const UserShow: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { queryResult } = useShow<IMember>();
  const { xl } = Grid.useBreakpoint();

  const user = queryResult?.data?.data as unknown as IMember;

  const { tableProps } = useTable<
    IMemberActivity,
    HttpError,
    IMemberActivityFilterVariables
  >({
    resource: "logs",
    initialSorter: [
      {
        field: "createdAt",
        order: "desc",
      },
    ],
    permanentFilter: [
      {
        field: "userId",
        operator: "eq",
        value: user?.id,
      },
    ],
    initialPageSize: 4,
    queryOptions: {
      enabled: user !== undefined,
    },
    syncWithLocation: false,
  });

  const tablePropsWorkshifts = useTable<
    IShift,
    HttpError,
    IShiftFilterVariables
  >({
    resource: "shifts",
    initialSorter: [
      {
        field: "createdAt",
        order: "desc",
      },
    ],
    permanentFilter: [
      {
        field: "userId",
        operator: "eq",
        value: user?.id,
      },
      {
        field: "year",
        operator: "gte",
        value: '1999'
      }
    ],
    initialPageSize: 4,
    syncWithLocation: false,
  });

  const operationStatusColor = (
    operation: string,
  ): { color: string; text: string } | undefined => {
    switch (operation) {
      case "CREATED":
        return { color: "orange", text: "created" };
      case "LIST":
        return { color: "cyan", text: "list" };
      case "SHOW":
        return { color: "green", text: "show" };
      case "UPDATE":
        return { color: "pink", text: "update" };
      case "DELETE":
        return { color: "red", text: "delete" };
      case "LOGIN":
        return { color: "geekblue", text: "login" };
      case "UPLOAD_ICON":
        return { color: "volcano", text: "upload_icon" };
      default:
        return { color: "green", text: "" };
    }
  };

  const getImageByDocumentType = (fileType: string) => {
    switch (fileType) {
      case "application/pdf":
        return "pdf.png"
      case "text/plain":
        return "txt.png"
      case "text/csv":
        return "csv.png"
      case "application/vnd.ms-powerpoint":
        return "ppt.png"
      case "image/png":
        return "png.png"
      case "application/zip":
        return "zip.png"
      case "application/vnd.rar":
        return "rar.png"
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        return "doc.png"
      case "application/xml":
      case "text/xml":
      case "application/atom+xml":
        return "xml.png"
      case "application/vnd.ms-excel":
        return "xls.png"
    }
    return "file_default.png"
  }

  function getFullDate(partitionDate: number, hour: number, minute: number) {
    const year = parseInt(partitionDate.toString().substring(0, 4));
    const month = parseInt(partitionDate.toString().substring(4, 6)) - 1;
    const day = parseInt(partitionDate.toString().substring(6));

    const date = new Date(year, month, day, hour, minute);

    return date
  }

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xl={5} xs={24}>
          <Space direction="vertical" style={{ width: "100%" }}>
            <Card bordered={false} style={{ height: "50%", border: '1px black', boxShadow: '0 2px 2px rgba(0, 0, 0, 0.5)' }}>
              <Space
                direction="vertical"
                style={{
                  textAlign: "center",
                  width: "100%",
                }}
              >

                <Avatar
                  size={120}
                  src={
                    (user?.avatarUrl &&
                      Array.isArray(user?.avatarUrl) &&
                      user?.avatarUrl[0]?.url) ||
                    "/images/man.png"
                  }
                ></Avatar>
                <Typography.Title level={3}>
                  {user?.displayName}
                </Typography.Title>
              </Space>
            </Card>
            <Card bordered={false} style={{ height: "50%", border: '1px black', boxShadow: '0 2px 2px rgba(0, 0, 0, 0.5)' }}>
              <Space
                direction="vertical"
                style={{ width: "100%", height: "100%" }}
              >
                <Space direction="horizontal" align="center">
                  <Typography.Text strong>EMAIL </Typography.Text>
                  <Typography.Text style={{ fontSize: "12px", overflowWrap: "break-word" }}>{user?.email}</Typography.Text>
                </Space>

                <Space direction="horizontal">
                  <Typography.Text strong>TELEFONO </Typography.Text>
                  <Typography.Text style={{ fontSize: "12px", overflowWrap: "break-word" }}>{user?.phone || "(---) -------"}</Typography.Text>
                </Space>

                <Space
                  direction="vertical"
                >

                  <Space direction="horizontal">
                    <Typography.Text strong>RUOLO: </Typography.Text>
                    <Tag color={user?.role === "ADMIN" ? "#e3c6ff " : "gold"} style={{ fontSize: "12px", fontWeight: "500", border: "1px" }}>{t(`users.fields.role.${user?.role.toLocaleLowerCase()}`)}</Tag>
                  </Space>

                  <Space direction="horizontal">
                    <Typography.Text strong>STATO: </Typography.Text>
                    {user?.isActive
                      ? <Tag color={"green"}>{t("users.fields.isActive.true")}</Tag>
                      : <Tag color={"red"}>{t("users.fields.isActive.false")}</Tag>}
                  </Space>
                </Space>

                {
                  (user &&
                    user.startHrPause != null &&
                      user.startMinPause != null && 
                        user.endHrPause != null &&
                          user.endMinPause != null) ? (
                            <Space direction="horizontal">
                            <Typography.Text strong>PAUSA:</Typography.Text>
                            <Typography.Text>{`${user.startHrPause.toString().padStart(2, '0')}:${user.startMinPause.toString().padStart(2, '0')} - ${user.endHrPause.toString().padStart(2, '0')}:${user.endMinPause.toString().padStart(2, '0')}` }</Typography.Text>
                          </Space>
                        ) : (
                          <Space direction="horizontal">
                            <Typography.Text strong>PAUSA:</Typography.Text>
                            <Typography.Text>non disponibile</Typography.Text>
                          </Space>
                        )
                }
              </Space>
            </Card>
          </Space>
        </Col>
        <Col xl={19} lg={24} xs={24}>
          <Row gutter={[16, 16]}>
            <Space direction="vertical" style={{ width: "100%"}}>
              <Typography.Title level={4}>Turni dell'utente</Typography.Title>
              <Table {...tablePropsWorkshifts.tableProps} locale={{
                emptyText: () => (<Typography.Text strong style={{ opacity: "0.4" }}>Nessun Turno disponibile</Typography.Text>)
              }} rowKey="id" style={{ height: "92%", boxShadow: '0 1px 2px rgba(0, 0, 0, 0.5)', padding: "20px" }}>
                <Table.Column
                  key="userId"
                  dataIndex="userId"
                  title={t("shifts.fields.operator")}
                  render={(value: string, _: IShift) => (
                    <Space align="start">
                      <div className="product">
                        <Avatar
                          shape="square"
                          size={{
                            md: 40,
                            lg: 64,
                            xl: 64,
                            xxl: 64,
                          }}
                          src={_?.userId?.avatarUrl?.length > 0 ? _.userId?.avatarUrl[0].url : "/images/man.png"}
                        />
                        <div className="product-text">
                          <Space direction="vertical">
                            <Typography.Text style={{ fontSize: 14, fontWeight: 800 }}>
                              {_?.userId.displayName}
                            </Typography.Text>
                            <Typography.Text style={{ fontSize: 13 }}>
                              {_?.userId.email}
                            </Typography.Text>
                            <Typography.Text style={{ fontSize: 12 }}>
                              #{_?.userId._id}
                            </Typography.Text>
                          </Space>

                        </div>
                      </div>
                    </Space>
                  )}
                />
                <Table.Column
                  key="title"
                  dataIndex="title"
                  title={t("shifts.fields.note")}
                />
                <Table.Column
                  key="startHr"
                  dataIndex="startHr"
                  align="center"
                  title={t("shifts.fields.entrance")}
                  render={(value: any, record: any) => <DateField value={new Date(getFullDate(record.partitionDate, record.startHr, record.startMin))} locales={require("dayjs/locale/it")} format="LLL" />}
                  sorter={(a: number, b: number) => b - a}
                />
                <Table.Column
                  key="endHr"
                  dataIndex="endHr"
                  align="center"
                  title={t("shifts.fields.exit")}
                  render={(value: any, record: any) => (
                    record?.endHr != null ? (
                      <DateField value={new Date(getFullDate(record.partitionDate, record.endHr, record.endMin))} locales={require("dayjs/locale/it")} format="LLL" />
                    ) : ("NON TIMBRATO")
                  )}
                  sorter={(a: number, b: number) => b - a}
                />
                <Table.Column
                  key="totalHr"
                  align="center"
                  dataIndex="totalHr"
                  title={t("shifts.fields.totalShow")}
                  render={(value: any, record: IShift) => (
                    record?.endHr != null ? (
                      <Typography.Text>{value + ' hr ' + record?.totalMin + ' min'}</Typography.Text>
                    ) : ("--")
                  )}
                />
              </Table>
            </Space>
          </Row>
          {
            user?.role === "ADMIN" && (
              <Row gutter={[16, 16]}>
                <Space direction="vertical" style={{ width: "100%"}}>
                  <Typography.Title level={4}>Log</Typography.Title>
                  <Table {...tableProps} locale={{
                emptyText: () => (<Typography.Text strong style={{ opacity: "0.4" }}>Nessun log disponibile</Typography.Text>)
              }} rowKey="id" style={{ border: '1px black', boxShadow: '0 2px 10px rgba(0, 0, 0, 0.5)', padding: "20px" }}>
                    <Table.Column
                      key="createdAt"
                      dataIndex="createdAt"
                      title={t("logs.fields.createdAt")}
                      render={(value) => <DateField value={new Date(0).setUTCSeconds(value)} locales={require("dayjs/locale/it")} format="LLL" />}
                      sorter
                    />
                    <Table.Column
                      key="userId"
                      dataIndex="userId"
                      title={t("logs.fields.userId")}
                      render={(value: string, _: ILog) => (

                        <div className="product">
                          <Avatar
                            size={{
                              md: 40,
                              lg: 64,
                              xl: 64,
                              xxl: 64,
                            }}
                            src={_?.userAvatarUrl}
                          />
                          <div className="product-text">
                            <Typography.Text style={{ fontSize: 18, fontWeight: 800 }}>
                              {_.userDisplayName}
                            </Typography.Text>
                            <Typography.Text style={{ fontSize: 10 }}>#{_?.userId}</Typography.Text>
                          </div>
                        </div>
                      )}
                    />
                    <Table.Column
                      key="operation"
                      dataIndex="operation"
                      title={t("logs.fields.operation")}
                      render={(value: string, _: ILog) => (
                        <>
                          <Row>
                            <Tag color={operationStatusColor(value)?.color}>{value + " " + _.onModelType /* + " " + _.onModelRef */}</Tag>
                          </Row>
                          {_?.onModelRef ? (<Row>
                            <Tag color={"#C3C5C5"}>{_.onModelRef}</Tag>
                          </Row>) : (null)}
                        </>
                      )}
                    />
                  </Table>
                </Space>
              </Row>
           )
          }
        </Col>
      </Row>
    </>
  );
};
