import React, { useState } from "react";
import { Menu } from "antd";
import {
    UnorderedListOutlined,
} from "@ant-design/icons";
import {
    useTranslate,
    useLogout,
    CanAccess,
    ITreeMenu,
    useIsExistAuthentication,
    useRouterContext,
    useMenu
} from "@pankod/refine-core";

import { RefineLayoutSiderProps } from "@pankod/refine-ui-types";
import { Avatar, Space, Typography } from "@pankod/refine-antd";
const { SubMenu } = Menu;

export const HeaderMenu: React.FC<RefineLayoutSiderProps> = ({ render }) => {
    const [collapsed] = useState<boolean>(false);
    const isExistAuthentication = useIsExistAuthentication();
    const { Link } = useRouterContext();
    const { mutate: mutateLogout } = useLogout();
    const translate = useTranslate();
    const { menuItems, selectedKey, defaultOpenKeys } = useMenu();
    
    const renderTreeView = (tree: ITreeMenu[], selectedKey: string) => {
        return tree.map((item: ITreeMenu) => {
            const { icon, label, route, name, children, parentName } = item;

            if (children.length > 0) {
                return (
                    <CanAccess
                        key={route}
                        resource={name.toLowerCase()}
                        action="list"
                        params={{
                            resource: item,
                        }}
                    >
                        <SubMenu
                            key={route}
                            icon={icon ?? <UnorderedListOutlined />}
                            title={label}

                        >
                            {renderTreeView(children, selectedKey)}
                        </SubMenu>
                    </CanAccess>
                );
            }
            const isSelected = route === selectedKey;

            return (
                <CanAccess
                    key={route}
                    resource={name.toLowerCase()}
                    action="list"
                    params={{
                        resource: item,
                    }}
                >
                    <Menu.Item
                        key={route}
                        style={{
                            fontWeight: isSelected ? "bold" : "normal",
                            backgroundColor: isSelected ? '#f2f2f2' : 'initial'
                        }}
                    >
                        <Link to={route} style={{ color: isSelected ? "black" : "white" }}>{label}</Link>
                    </Menu.Item>
                </CanAccess>
            );
        });
    };

    const logout = isExistAuthentication && (
        <Menu.Item
            key="logout"
            onClick={() => mutateLogout()}
            style={{ color: 'white' }}
            icon={collapsed ? <Avatar shape="square" size={24} src="/images/exit.png" /> : null}
        >
            {translate("buttons.logout", "Logout")}
        </Menu.Item>
    );

    const items = renderTreeView(menuItems, selectedKey);

    return (
        <>
            <Space direction="horizontal" style={{ gap: "30px" }}>
                <Avatar src="/images/logo.png" size={32}/>
                <Menu
                    selectedKeys={[selectedKey]}
                    style={{ width: "700px" }}
                    defaultOpenKeys={defaultOpenKeys}
                    mode="horizontal"
                >
                    {items}
                    {logout}
                </Menu>
            </Space>
        </>
    );
};
