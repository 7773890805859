import { CSSProperties } from "react";

export const layoutStyles: CSSProperties = {
    background: `radial-gradient(30% 0% at 0% 0%, #c6cfbf 0%, #3f3c7e 100%)`,
    backgroundSize: "cover",
};

export const containerStyles: CSSProperties = {
    maxWidth: "408px",
    margin: "auto",
    backgroundColor: "#C8C8C8",
    border: "none",
    borderRadius: "30px"
};

export const titleStyles: CSSProperties = {
    textAlign: "center",
    color: "#0047AB",
    fontSize: "36px",
    letterSpacing: "-0.04em",
    overflowWrap: "break-word",
    hyphens: "manual",
    textOverflow: "unset",
    whiteSpace: "pre-wrap",
    paddingTop: "25px"
};
